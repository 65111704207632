/**
 * File: csv.ts
 *
 * Copyright:
 * Copyright 2017-2021. Parallels International GmbH. All Rights Reserved.
 *
 * */

import { Dictionary } from './types';

type Row = string[];

export class CsvReader {

  unescape (value: string): string {
    return value.replace('""', '"');
  }

  stringToRow (str: string): Row {
    let
      row: Row = [],
      current = str,
      elem = '',
      // split string into two: next csv value and rest of the string
      split = /(.*?[^"])"?,(.*)/;

    while (current) {
      if (current[0] === '"') {
        current = current.substr(1);
      }

      if (split.test(current)) {
        [, elem, current] = split.exec(current);
      } else {
        elem = current.replace(/"$/, '');
        current = '';
      }

      row.push(this.unescape(elem));
    }

    return row;
  }

  toArray (str: string): Row[] {
    return str.replace(/[\r\n]+/g, '\n').split('\n').filter((s) => s !== '').map(this.stringToRow, this);
  }
}

interface Field {
  value: string;
  text: string;
}

export class CsvWriter {
  fields: (Field & string)[];
  quotes: boolean;

  constructor (fields: (Field & string)[], quotes: boolean = true) {
    this.fields = fields;
    this.quotes = quotes;
  }

  escape (value): string {
    if (typeof value !== 'string') {
      value = value !== undefined && value !== null ? String(value) : '';
    }
    return value.replace('"', '""');
  }

  elementToRow (element: Dictionary): Row {
    return this.fields.map((field) => {
      if (field.value) {
        return element[field.value];
      }
      return element[field];
    });
  }

  rowToString (row: Row): string {
    if (this.quotes) {
      return '"' + row.map(this.escape).join('","') + '"';
    } else {
      return row.map(this.escape).join(',');
    }
  }

  toString (source: Row[]): string {
    return source.map(this.elementToRow, this).map(this.rowToString, this).join('\n');
  }

  getHeadersRow (): string {
    const fields = this.fields.map((field) => {
      return this.escape(field.text || field);
    });

    if (this.quotes) {
      return '"' + fields.join('","') + '"' + '\n';
    } else {
      return fields.join(',') + '\n';
    }
  }

  getContent (rows: Row[]) : string {
    return this.getHeadersRow() + this.toString(rows);
  }
}
