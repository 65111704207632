/**
 * File: desktopRoutes.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/

import {
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDB_PER_USER
} from '@core/constants/subscriptions';

import DesktopPage from '@/modules/desktop/desktopPage.vue';
import DesktopLitePage from '@/modules/desktop/desktopLitePage.vue';
import DesktopPreviewPage from '@/modules/desktop/preview.vue';
import DesktopM1BetaPage from '@/modules/desktop/desktopM1Beta.vue';
import RedeemCodes from '@/modules/desktop/redeemCodes.vue';
import PackagedLicenses from '@/modules/desktop/packagedLicenses.vue';
import LegacyLicensesContent from '@/modules/legacyLicenses/legacyLicensesContent.vue';
import {
  DESKTOP_COMPUTERS_PAGE,
  DESKTOP_CONFIGURATION_PROFILES_PAGE,
  DESKTOP_DOWNLOADS_PAGE,
  DESKTOP_LITE_SUBSCRIPTION_DETAILS_PAGE,
  DESKTOP_LITE_SUBSCRIPTIONS_PAGE,
  DESKTOP_M1_BETA_PAGE,
  DESKTOP_PREVIEW_PAGE,
  DESKTOP_SUBSCRIPTION_DETAILS_PAGE,
  DESKTOP_SUBSCRIPTIONS_PAGE,
  REDEEM_CODES_PAGE,
  PACKAGED_LICENSES_PAGE,
  COMPUTERS_CONTENT,
  DOWNLOADS_CONTENT,
  SUBSCRIPTIONS_CONTENT,
  DESKTOP_SUBSCRIPTION_DETAILS_CONTENT,
  CONFIGURATION_PROFILES_CONTENT,
  SUBSCRIPTION_DETAILS_CONTENT,
  LEGACY_LICENSES_CONTENT,
  DESKTOP_LEGACY_LICENSES_PAGE,
  DESKTOP_SSO_USERS_PAGE,
  SSO_USERS_CONTENT,
  DESKTOP_SSO_MEMBER_DETAILS_PAGE
} from '@/routes/constants';
import DesktopSSOMemberDetailsPage from '@/modules/desktop/desktopSSOMemberDetailsPage.vue';

export default [
  {
    path: '/desktop/preview/:page(challenge)?',
    name: DESKTOP_PREVIEW_PAGE,
    component: DesktopPreviewPage,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Technical Preview - Parallels Desktop for Mac',
    },
  },
  {
    path: '/desktop/beta',
    name: DESKTOP_M1_BETA_PAGE,
    component: DesktopM1BetaPage,
    meta: {
      requiresAuth: true,
      title: 'Parallels Desktop for M-series Mac Technical Preview',
    },
  },
  {
    path: `/desktop/${PRODUCT_KEY_NAME_PDFC}/codes`,
    name: REDEEM_CODES_PAGE,
    component: RedeemCodes,
    meta: {
      requiresAuth: true,
      title: 'Parallels Desktop for Chrome OS Redemption Codes',
    },
  },
  {
    path: `/desktop/${PRODUCT_KEY_NAME_PDFC}/packaged_licenses`,
    name: PACKAGED_LICENSES_PAGE,
    component: PackagedLicenses,
    meta: {
      requiresAuth: true,
      title: 'Parallels Desktop for Chrome OS Packaged Licenses',
    },
  },
  {
    path: '/desktop/:product?/computers',
    name: DESKTOP_COMPUTERS_PAGE,
    component: DesktopPage,
    props: (route) => ({
      content: COMPUTERS_CONTENT,
      product: route.params.product,
    }),
    meta: {
      requiresAuth: true,
      title: 'Computers - Parallels Desktop for Mac',
    },
  },
  {
    path: '/desktop/:product?/downloads',
    name: DESKTOP_DOWNLOADS_PAGE,
    component: DesktopPage,
    props: (route) => ({
      content: DOWNLOADS_CONTENT,
      product: route.params.product,
    }),
    meta: {
      requiresAuth: true,
      title: 'Download - Parallels Desktop for Mac',
    },
  },
  {
    path: '/desktop/:product?/subscriptions',
    name: DESKTOP_SUBSCRIPTIONS_PAGE,
    component: DesktopPage,
    props: (route) => ({
      content: SUBSCRIPTIONS_CONTENT,
      product: route.params.product,
    }),
    meta: {
      requiresAuth: true,
      title: 'Subscriptions - Parallels Desktop for Mac',
    },
  },
  {
    path: '/desktop/:product?/subscriptions/:id',
    name: DESKTOP_SUBSCRIPTION_DETAILS_PAGE,
    component: DesktopPage,
    props: (route) => ({
      content: DESKTOP_SUBSCRIPTION_DETAILS_CONTENT,
      product: route.params.product,
    }),
    meta: {
      requiresAuth: true,
      title: 'Subscriptions - Parallels Desktop for Mac',
    },
  },
  {
    path: `/desktop/${PRODUCT_KEY_NAME_PDFM}/legacy`,
    name: DESKTOP_LEGACY_LICENSES_PAGE,
    component: LegacyLicensesContent,
    props: {
      content: LEGACY_LICENSES_CONTENT,
    },
    meta: {
      requiresAuth: true,
      title: 'Download - Parallels Desktop for Mac',
    },
  },
  {
    path: '/desktop/:product?/configuration_profiles',
    name: DESKTOP_CONFIGURATION_PROFILES_PAGE,
    component: DesktopPage,
    props: (route) => ({
      content: CONFIGURATION_PROFILES_CONTENT,
      product: route.params.product,
    }),
    meta: {
      requiresAuth: true,
      title: 'Configuration Profiles - Parallels Desktop for Mac',
    },
  },
  {
    path: `/desktop/${PRODUCT_KEY_NAME_PDB_PER_USER}/sso_users`,
    name: DESKTOP_SSO_USERS_PAGE,
    component: DesktopPage,
    props: (route) => ({
      content: SSO_USERS_CONTENT,
      product: PRODUCT_KEY_NAME_PDB_PER_USER,
    }),
    meta: {
      requiresAuth: true,
      title: 'SSO Users - Parallels Desktop for Mac',
    },
  },
  {
    path: '/desktop-lite/subscriptions',
    name: DESKTOP_LITE_SUBSCRIPTIONS_PAGE,
    component: DesktopLitePage,
    props: (route) => ({
      content: SUBSCRIPTIONS_CONTENT,
    }),
    meta: {
      requiresAuth: true,
      title: 'Subscriptions - Parallels Desktop App Store Edition',
    },
  },
  {
    path: '/desktop-lite/subscriptions/:id',
    name: DESKTOP_LITE_SUBSCRIPTION_DETAILS_PAGE,
    component: DesktopLitePage,
    props: (route) => ({
      content: SUBSCRIPTION_DETAILS_CONTENT,
    }),
    meta: {
      requiresAuth: true,
      title: 'Subscriptions - Parallels Desktop App Store Edition',
    },
  },
  {
    name: DESKTOP_SSO_MEMBER_DETAILS_PAGE,
    path: `/desktop/${PRODUCT_KEY_NAME_PDB_PER_USER}/sso_users/:memberId`,
    component: DesktopSSOMemberDetailsPage,
    props: true,
    meta: {
      requiresAuth: true,
      title: 'User Details',
    },
  },
];
