
import Vue from 'vue';

import { copyExcluding } from '@core/common/utils';
import ComponentMixIn from '@/modules/componentMixIn';
import ContractRequest from '@/api/contractRequest';
import ContractDownloadUrlRequest from '@/api/contractDownloadUrlRequest';
import { ContractDetailsModalTabEvent } from '@/modules/subscriptions/details/subscriptionDetailsContent';

export default Vue.extend({
  name: 'contract-details-modal',

  mixins: [ComponentMixIn],

  props: {
    subscription: {
      type: Object,
    },
  },

  data () {
    return {
      loading: false,
      tab: this.$route.query.tab,
      tabs: {
        general: this.$t('General'),
        sales: this.$t('Sales Representative'),
      },
      contract: { contractDetails: {}, salesRepresentative: {} },
    };
  },

  methods: {
    switchTab (params: { tab: string }) {
      this.tab = params.tab;
    },

    show (options: ContractDetailsModalTabEvent = {}): void {
      if (options.tab) {
        this.tab = options.tab;
      }
      this.load();
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16310
      this.$refs.modal.show();
      this.$emit('show');
    },

    load (): Promise<void> {
      const request = new ContractRequest({
        uuid: this.subscription.uuid,
        service: this.subscription.targetServiceName,
      });

      return this.authorizedCall(request).then((data) => {
        this.contract = data;
      });
    },

    handleDownload (): Promise<void> {
      const request = new ContractDownloadUrlRequest({
        uuid: this.subscription.uuid,
        service: this.subscription.targetServiceName,
      });

      return this.authorizedCall(request)
        .then((data) => {
          window.location = data.download_link;
        });
    },

    onHide (): void {
      this.$emit('hide');
      this.$router.replace({ query: copyExcluding(this.$route.query, ['tab']) });
    },
  },

  watch: {
    tab (val: string): void {
      this.$router.replace({ query: Object.assign({}, this.$route.query, { tab: val }) });
    },
  },
});

