import { render, staticRenderFns } from "./farmsTable.vue?vue&type=template&id=37a5310e&scoped=true&lang=pug"
import script from "./farmsTable.vue?vue&type=script&lang=ts"
export * from "./farmsTable.vue?vue&type=script&lang=ts"
import style0 from "./farmsTable.vue?vue&type=style&index=0&id=37a5310e&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a5310e",
  null
  
)

/* custom blocks */
import block0 from "./farmsTable.vue?vue&type=custom&index=0&blockType=copyright"
if (typeof block0 === 'function') block0(component)

export default component.exports