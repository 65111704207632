<copyright>
File: index.vue

Copyright:
Copyright 2017-2021. Parallels International GmbH. All Rights Reserved.
</copyright>

<script>

export default {
  name: 'error-page',
  props: ['appData'],
  data () {
    return {
      year: (new Date()).getFullYear()
    };
  }
};

</script>

<template lang="pug">

.error-page
  .logo
  template(v-if="$slots.content")
    slot(name="content")
  template(v-else)
    slot(name="image")
      .image
    h1
      slot(name="header")
        .text-big 404
    .error-content
      .error-text
        slot(name="text")
          | {{ $t('The page could not be found or cannot be displayed due to unexpected error. A report has been sent to our technical support. We will resolve the problem as soon as possible.') }}
      slot(name="button")
        btn(@click="$router.back()") {{ $t('Go Back') }}
  .copyright &copy; {{ year }} Parallels International GmbH. {{ $t('All rights reserved.') }}

</template>

<style scoped lang="sass">

.error-page
  padding: $vertical-step * 3 $gutter-width * 2
  margin-bottom: -$footer-height
  +tablet-only
    margin-bottom: -$footer-tablet-height
  +phone
    margin-bottom: -$footer-phone-height

.logo
  width: 162px
  height: 35px
  margin: 0 auto 74px auto
  background: url(assets/logo.svg) no-repeat

.image
  width: 285px
  height: 232px
  margin: 0 auto $vertical-step * 2 auto
  background: url(assets/error.svg) no-repeat

h1
  font-size: 44px
  line-height: 1em
  font-weight: $light
  color: $steel-grey
  text-align: center

.text-big
  font-size: 66px
  line-height: 1em

.error-content
  max-width: 550px
  margin: auto
  text-align: center

.error-text
  margin-bottom: $vertical-step * 2

.copyright
  margin-top: $grid-step * 2
  line-height: $grid-step * 3
  color: $steel-grey
  text-align: center
  font-size: $extra-small-font-size

</style>
