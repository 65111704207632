/**
 * File: datetime.ts
 *
 * Copyright:
 * Copyright 2018-2021. Parallels International GmbH. All Rights Reserved.
 *
 */

export const getMilitaryTimezone = function (): string {
  // "12:57:14 GMT+0300 (MSK)"
  return /([+-]\d+)/.exec(new Date().toTimeString())[0];
};

// todo: replace with `Record<'hours' | 'minutes', number>` after CPCLOUD-16075
interface Duration {
  hours: number;
  minutes: number;
}

export const getDuration = function (duration: number = 0): number | Duration {
  const result: Duration = {
    hours: duration / 60,
    minutes: duration % 60
  };
  return result.hours || result.minutes ? result : null;
};

export enum WeekDay {
  Monday = 0, // $t('Monday')
  Tuesday = 1, // $t('Tuesday')
  Wednesday = 2, // $t('Wednesday')
  Thursday = 3, // $t('Thursday')
  Friday = 4, // $t('Friday')
  Saturday = 5, // $t('Saturday')
  Sunday = 6, // $t('Sunday')
}
