/**
 * File: links.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import {
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_PAX,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDB_PER_USER,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDL,
  PRODUCT_KEY_NAME_PMM,
  PRODUCT_KEY_NAME_PTB,
  PRODUCT_KEY_NAME_PTBB,
  PRODUCT_KEY_NAME_PVAD,
  PRODUCT_KEY_NAME_RAS
} from './subscriptions';
import { Dictionary } from '@core/common/types';

const domain = '//www.parallels.com';
const licenseServicePath = '/license/webapp/';

export const support: Dictionary<string> = {
  [PRODUCT_KEY_NAME_PDB]: `${domain}/products/business/support/`,
  [PRODUCT_KEY_NAME_PDB_PER_USER]: `${domain}/products/business/support/`,
  [PRODUCT_KEY_NAME_RAS]: `${domain}/products/ras/support/`,
  [PRODUCT_KEY_NAME_PTB]: `${domain}/products/toolbox/support/`,
  [PRODUCT_KEY_NAME_PTBB]: `${domain}/products/toolbox/support/`,
  [PRODUCT_KEY_NAME_PMM]: `${domain}/products/mac-management/support/`,
  [PRODUCT_KEY_NAME_PAX]: `${domain}/products/access/support/`,
  [PRODUCT_KEY_NAME_PDFM]: `${domain}/products/desktop/support/`,
  [PRODUCT_KEY_NAME_PDL]: `${domain}/products/desktop/support/`,
  [PRODUCT_KEY_NAME_PVAD]: `${domain}/products/desktop/support/`, // FIXME https://jira.prls.net/browse/CPCLOUD-18938
  [PRODUCT_KEY_NAME_PSW]: `${domain}/products/desktop/support/`,
  facebook: 'https://m.me/ParallelsInc',
  twitter: 'https://twitter.com/parallelscares',
  base: `${domain}/support/`
};

export const licenseService = {
  DOMAINS_WITH: `${licenseServicePath}domains_with`,
  USER_BY_LICENSE_KEY: `${licenseServicePath}user_by_license_key`,
  ALL_PRODUCTS_PER_USER: `${licenseServicePath}all_products_per_user`,
  OEM_PARTNER_ABUSED: `${licenseServicePath}oem_partner/abused`,
  RESTORE_PURCHASES: `${licenseServicePath}restore_purchases`,
  PRODUCTS: `${licenseServicePath}products`,
  REDEEM_CODES_ABUSED: `${licenseServicePath}redeem_codes/abused`
};

export const AAS_SUBSCRIPTION_MANAGEMENT_URL = 'https://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/manageSubscriptions';
export const GPS_SUBSCRIPTION_MANAGEMENT_URL = 'https://play.google.com/store/account/subscriptions';

export const SIWA_SDK_SCRIPT_URL = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

export const SKYPE_SUPPORT_LINK = 'skype:+18888112489?call';

export const GOOGLE_ADMIN_CONSOLE_URL = 'https://support.google.com/chrome/a/answer/9681911';

export const KB_LINK_RAS_NFR: string = 'https://kb.parallels.com/125180';

export const KB_LINK = 'https://kb.parallels.com/';

export const KB_LINK_EXTENDED_SESSION: string = 'https://kb.parallels.com/125342';

export const LEGAL_NOTICES_URL = 'https://www.parallels.com/about/legal';

export const PRIVACY_POLICY_URL = 'https://www.parallels.com/privacy';

export const HELP_WITH_SIGN_IN_URL = 'https://kb.parallels.com/113624';

export const SYSTEM_STATUS = 'https://status.parallels.com';

export const HELP_UPGRADE_TO_PRO_URL = 'https://kb.parallels.com/123159';

export const KB_LINK_EXTERNAL_BUNDLE: string = 'https://kb.parallels.com/129496';

export const DOWNGRADING_SUBSCRIPTION_PD = 'https://kb.parallels.com/129472';

export const KB_LINK_PSW = 'https://kb.parallels.com/en/129726';

export const PSW_EXCHANGE_KEY_LINK = 'https://forms.office.com/pages/responsepage.aspx?id=IUeHM3fdfkuXt4Y8LL58cy9bdGwHYttEizFcwmlpPghUNDFUNzJDTVdWODlXVTJXRlI2R0ZHQjhKMS4u';

export const DAAS_PRODUCT_LINK = 'https://www.parallels.com/products/daas';

export const PBI_PRODUCT_LINK = 'https://www.parallels.com/products/browser-isolation';
