<copyright>
File: productCardPDL.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<template lang="pug">

card(type="product", :equalTo="equalTo", v-bind:class="[ defaultClass ]", icon="pd_lite")
  template(slot="title")
    .text-ellipsis {{ $t('Parallels Desktop App Store Edition') }}
  template(slot="about") {{ $tc('for {limit} computers', item.totalLimit, { limit: item.totalLimit }) }}
  template(slot="info")
    list.padding-bottom-xs
      router-link(
        :to="{ name: 'desktopLiteSubscriptions', query: { display: STATUS_ACTIVE } }",
        :data-name="$name('link-active-pdl-subscriptions')"
      )
        .row
          .col-xs-10.text-ellipsis {{ $t('Active subscriptions') }}
          .col-xs-1
            router-link(
              v-if="item.hasExpiringSubscriptions",
              :to="{ name: 'desktopLiteSubscriptions', query: { display: STATUS_IS_EXPIRING_SOON } }",
              :data-name="$name('link-expiring-pdl-subscriptions')"
            )
              icon(name="alert-yellow").pull-right
          .col-xs-1.text-right.text-link.text-bold {{ item.activeSubscriptions }}
      router-link(
        v-if="item.expiredSubscriptions",
        :to="{ name: 'desktopLiteSubscriptions', query: { display: STATUS_EXPIRED } }",
        :data-name="$name('link-expired-pdl-subscriptions')"
      )
        .row
          .col-xs-9.text-ellipsis {{ $t('Expired subscriptions') }}
          .col-xs-3.text-right.text-link.text-bold {{ item.expiredSubscriptions }}
  template(slot="menu")
    btn.block-xs.margin-right-2x-sm.margin-top-2x(@click="$router.push({ name: 'convertPdl', params: { id: 'null' }, replace: true })", size="small", color="white")
      | {{ $t('Upgrade') }}
    btn.block-xs.margin-top-2x(target="_blank", size="small", color="white", @click="onSupportClick")
      | {{ $t('Support') }}

</template>

<script>

import ProductCardMixIn from './productCardMixIn';

export default {
  name: 'product-card-pdl',
  mixins: [ProductCardMixIn],
};

</script>
