<copyright>
File: subnavigation.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import { Subnavigation } from '@parallels/ui-kit';

export default {
  name: 'subnavigation-my',
  components: { Subnavigation },

  mounted () {
    try {
      const subnavigation = document.querySelector('.subnavigation');
      const items = Array.from(subnavigation.querySelectorAll('.item'));
      items.forEach((item) => {
        item.children[0].setAttribute('data-qa', item.children[0].innerText.toLowerCase().replace(/\([^()]*\)/g, '').trim().replace(/ /g, '-'));
      });
    } catch (error) {
    }
  },
};

</script>

<template lang="pug">

//- $listeners - listen and pass all events to parent
//- $attrs - bind every property from this component to table view
subnavigation(v-on="$listeners", v-bind="$attrs", :dropdown-label="$t('More')")

</template>
