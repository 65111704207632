/**
 *
 * Simple easing function
 *
**/
function easeInOutQuad (time, start, change, duration) {
  time /= duration / 2;
  if (time < 1) {
    return change / 2 * time * time + start;
  }
  time--;
  return -change / 2 * (time * (time - 2) - 1) + start;
};

/**
 *  Scroll view to element
 *
 *  params: {
 *    from,    // HTMLElement scroll from - document.documentElement by default
 *    to,      // HTMLElement scroll to
 *    duration // time in ms - 1000ms by default
 *  }
 *
**/
export function scrollTo (params) {
  let { from, to, duration, delta } = Object.assign({
      from: document.documentElement,
      duration: 1000,
      delta: 0
    }, params),
    start = from.scrollTop,
    change = to.getBoundingClientRect().top + delta,
    currentTime = 0,
    increment = 20;

  function animateScroll () {
    currentTime += increment;
    let value = easeInOutQuad(currentTime, start, change, duration);
    from.scrollTop = value;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  }

  animateScroll();
}

/**
 *  Wrapper over scrollBy - smooth scroll not working in Safari
 *
 *  params: {
 *    element,  // HTMLElement for scroll
 *    duration, // scroll duration
 *    left      // horizontal scroll delta
 *  }
 *
**/
export function scrollBy (params) {
  params = Object.assign({}, {
    left: 0,
    duration: 350
  }, params);

  if ('scrollBehavior' in document.documentElement.style) {
    params.element.scrollBy({
      left: params.left,
      top: params.top,
      behavior: 'smooth'
    });
  } else {
    smoothHorizontalScroll(params.element, params.duration, params.left);
  }
}

function smoothHorizontalScroll (element, time, amount) {
  let currentTime = 0,
    scrollCounter = 0,
    start = element.scrollLeft;

  amount /= 100;

  while (currentTime <= time) {
    window.setTimeout(scrollElement, currentTime, element, scrollCounter, amount, start);
    currentTime += time / 100;
    scrollCounter++;
  }
}

function scrollElement (element, scrollCounter, amount, start) {
  element.scrollLeft = (amount * scrollCounter) + start;
}
