/**
 * File: item.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue, { PropType } from 'vue';
import { RawLocation } from 'vue-router/types/router';
import ComponentMixIn from '@/modules/componentMixIn';
import SubscriptionComponentMixIn from '@/modules/subscriptions/subscriptionMixIn';
import { PRODUCT_KEY_NAME_PMM, RKN_UPGRADE_INSURANCE } from '@core/constants/subscriptions';
import StatusLine from './statusLine/index.vue';
import Subscription, { ProductInfo, VIEW_STATUS } from '@/models/subscription';
import { RENEW_ONLINE_PAGE } from '@/routes/constants';
import { getSubscriptionLinkParams } from '@/modules/subscriptions/helpers';

const SUBSCRIPTION_EXPIRATION_SOON_DELTA = 30 * 86400 * 1000; // 30 days in milliseconds

export default Vue.extend({
  name: 'subscription-list-item',

  mixins: [ComponentMixIn, SubscriptionComponentMixIn],

  components: { StatusLine },

  props: {

    subscription: {
      type: Object as PropType<Subscription>,
    },

    index: {
      type: Number,
    },

    last: {
      type: Boolean,
    },

    contextProduct: {
      type: String,
    },

  },

  data () {
    return {
      upgradeInsuranceExpDate: null,
    };
  },

  computed: {
    azmpSubscriptionId (): string {
      return (this.subscription as Subscription).traits?.azmpSubscriptionId || '-';
    },
    azmpResourceUsageId (): string {
      return (this.subscription as Subscription).traits?.azmpResourceUsageId || '-';
    },

    subscriptionProducts (): { description: string; period: number | string }[] {
      return (this.subscription.products as Array<ProductInfo>).map(product => ({
        description: product.description,
        period: this.subscription.isDifferentProductsAmount ? product.licenses.thisPeriod : '',
      }));
    },

    subscriptionOptions (): { name: string; expiration: string | undefined }[] {
      const now = Date.now();

      return (this.subscription.options as Array<ProductInfo>)
        .map(option => {
          let expiration;

          if (option.licenses.objectType === 'datetime') {
            const expDate = new Date(option.licenses.limit * 1000).getTime();
            if (expDate < now) {
              expiration = 'expired';
            } else if (expDate - SUBSCRIPTION_EXPIRATION_SOON_DELTA < now) {
              expiration = 'expiringSoon';
            }
          }

          return {
            // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
            name: this.getResourceName(option.keyName),
            expiration,
          };
        });
    },

    // Indicates that subscription status must be marked in red.
    statusAlert (): boolean {
      const subs = this.subscription;
      return subs.isBlacklisted || subs.isExpired || subs.isSuspended || subs.isExpiringSoon;
    },

    statusLine (): string {
      const subscriptionType = this.subscription.isPermanent ? 'Permanent license' : 'Subscription';

      if (this.subscription.isBlacklisted) {
        return `${subscriptionType} blacklisted`;
      } else if (this.subscription.isSuspended) {
        return $t('Suspended');
      } else if (!this.subscription.isPermanent && this.subscription.isExpired) {
        return `${subscriptionType} expired on ${this.formatDate(this.subscription.expirationDate)}`;
      } else if (!this.subscription.isPermanent && !this.subscription.isPostPaid && this.subscription.isAutorenewable && this.subscription.nextBillingDate) {
        return `${subscriptionType} will renew on ${this.formatDate(this.subscription.nextBillingDate)}`;
      }

      if (this.subscription.isPermanent) {
        return subscriptionType;
      }

      return `${subscriptionType} expires on ${this.formatDate(this.subscription.expirationDate)}`;
    },

    upgradeInsuranceExpirationDate (): null | Date {
      const upgradeInsurance = this.subscription.options.find(option => option.keyName === RKN_UPGRADE_INSURANCE);
      return upgradeInsurance && new Date(upgradeInsurance.licenses.limit * 1000);
    },

    subscriptionLinkParams (): RawLocation {
      return getSubscriptionLinkParams(this.subscription, this.contextProduct, this.$route.params.utility);
    },
  },

  methods: {
    renewItemHandler (): void {
      let route = {
        name: RENEW_ONLINE_PAGE,
        params: {
          id: this.subscription.uuid,
          byRef: true,
          canBeMigrated: false,
        },
      };
      if (
        (this.subscription.viewStatus === VIEW_STATUS.SF_EXPIRED && !this.subscription.isGracePeriod) ||
        this.contextProduct === PRODUCT_KEY_NAME_PMM
      ) {
        // @ts-ignore
        route = Object.assign({}, this.subscriptionLinkParams, { query: { renew: true } });
      }
      // @ts-ignore
      this.$router.push(route);
    },

    autoRenewOnItemHandler (): void {
      const route = Object.assign({}, this.subscriptionLinkParams, { query: { turnAutoRenewOn: true } });
      this.$router.push(route);
    },

    convertPdlItemHandler (): void {
      this.$router.push({ name: 'convertPdl', params: { id: this.subscription.uuid } });
    },

  },

});
