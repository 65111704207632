/**
 * File: subscription.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import CoreModel from '@core/common/model';
import {
  CB_TRANSACTION_STATES,
  KEY_NAME_PDL,
  KEY_NAME_PMM,
  KEY_NAME_RAS_IN_APP_TRIAL,
  PRODUCT_KEY_NAME_DAAS,
  PRODUCT_KEY_NAME_PBI,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDB_PER_USER,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDL,
  PRODUCT_KEY_NAME_PMM,
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_PTBB,
  PRODUCT_KEY_NAME_PVAD,
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_UNITS,
  RasUpgradedSupportType,
  STORE_NAME_AAS,
  STORE_NAME_BYTEBOT,
  STORE_NAME_CB,
  STORE_NAME_CUSTOM,
  STORE_NAME_GPS,
  PRODUCT_KEY_NAME_RAS_AZMP
} from '@core/constants/subscriptions';
import Service from '@core/constants/services';
import UniversalKey from './universalKey';

export const STATUS_ACTIVE = 'Active'; // $t('Active')
export const STATUS_BLACKLISTED = 'Blacklisted'; // $t('Blacklisted')
export const STATUS_SUSPENDED = 'Suspended'; // $t('Suspended')
export const STATUS_EXPIRED = 'Expired'; // $t('Expired')
export const STATUS_IS_EXPIRING_SOON = 'Expiring soon'; // $t('Expiring soon')
export const STATUS_ALL = 'All'; // $t('All')
export const TYPE_ALL = 'All'; // $t('All')
export const TYPE_SUBSCRIPTION = 'Subscription'; // $t('Subscription')
export const TYPE_PERPETUAL = 'Perpetual'; // $t('Perpetual')
export const TYPE_SPLA = 'SPLA'; // $t('SPLA')
export const SUBSCRIPTION_EXPIRATION_SOON_DELTA = 30;

export const CONCURRENT_PRODUCT_MARK = 'cusr';
export const NAMED_PRODUCT_MARK = 'nusr';

export const DAY_MILLISECONDS = 1000 * 60 * 60 * 24;

export const PDL_DISPLAY_LIMIT = 5;

export const VIEW_STATUS = {
  PERMANENT: 1,
  BLACKLISTED: 2,
  SUSPENDED: 3,
  ACTIVE: 4,
  EXPIRE_SOON: 5,
  EXPIRED: 6,
  CARD_EXPIRED: 7,
  RENEW_FAILED: 8,
  WILL_RENEW: 9,
  AAS_EXPIRE_SOON: 10,
  AAS_EXPIRED: 11,
  PDL_EXPIRE_SOON: 12,
  PDL_EXPIRED: 13,
  SF_EXPIRE_SOON: 14,
  SF_EXPIRED: 15,
  CB_EXPIRED: 16,
  TRIAL: 17,
  BYTEBOT: 18,
  GPS_EXPIRE_SOON: 19,
  GPS_EXPIRED: 20,
};

export interface Modification {
  maxAmt: number;
  minAmt: number;
  increment: number;
  incrementCost: string;
  wholePeriodCost: string;
}

export interface LicensesInfo {
  limit: number;
  thisPeriod: number; //  Available machines during current period
  nextPeriod: number; // Available machines at next billing period
  ratio: number;
  usage: number;
  available: number;
  totalAvailable: number;
  objectType: string;
  modification?: Modification;
}

export interface ProductInfo {
  productKeyName: string;
  productName: string;
  keyName: string;
  subsetAllowed: boolean;
  isPrimaryResource: boolean;
  isMainResource: boolean;
  licenses: LicensesInfo;
  description: string;
}

interface ByteBotStatistics {
  usersAmount: number;
  devicesAmount: number;
}

interface Traits {
  awgAnonymousLicenseUsageReport?: boolean;
  awgConvertedFromLegacyAt?: string;
  awgCustomer?: string;
  azmpSubscriptionId?: string;
  azmpResourceUsageId?: string;
  awgDailyLicenseCheck?: boolean;
  awgInAppDownload?: boolean;
  awgLegacyLicenseId?: string;
  awgPartner?: string;
  rasUpgradedSupportType?: RasUpgradedSupportType;
  rasUpgradedSupportStartDate?: Date;
  rasUpgradedSupportEndDate?: Date;
}

export default class Subscription extends CoreModel {
  __name?: string;
  __isSellOptionApplicable?: boolean;
  __isActive?: boolean;
  __isExpired?: boolean;
  __universalKeys?: Array<Record<string, any>>;
  __canRenewOnline?: boolean;
  __nextBillingCost?: string;
  __nextBillingCostCurrency?: string;
  __licensedProducts?: Array<ProductInfo>;
  uuid: string;
  mainPeriodEndsAt: Date;
  isGracePeriod: boolean;
  isSuspended: boolean;
  nextBillingAt: Date;
  gracePeriodEndsAt: Date;
  plannedDowngradeAt: Date;
  activatedAt: Date;
  salesForce?: { hasContract: boolean };
  storeId: string;
  canAddLicense: boolean;
  canUpgradeOnline: boolean;
  isUpgrading: boolean;
  isDowngrading: boolean;
  duration: { units: string; value: number };
  isPostPaid: boolean;
  isBusiness: boolean;
  isLimited: boolean;
  isHidden: boolean;
  isNfr?: boolean;
  isPromo?: boolean;
  isBeta?: boolean;
  parentUuid?: string;
  storeLastTransactionState?: string;
  isAutorenewable: boolean;
  bytebotStatistics?: ByteBotStatistics;
  licKey: string;
  isTrial?: boolean;
  perUserHostsLimit?: number;
  isLicenseManager?: boolean;
  traits?: Traits;

  isExternalBundle?: boolean;

  get name (): string {
    return this.__name || '';
  }

  set name (value: string) {
    this.__name = value;
  }

  get subsetAllowed (): boolean {
    return this.products.some((product) => product.subsetAllowed);
  }

  get isPermanent (): boolean {
    if (!this.isPrimary) {
      return false;
    }
    // This property is absent in case of "short" response from backend after key registration
    if (!this.hasOwnProperty('mainPeriodEndsAt') || this.mainPeriodEndsAt) {
      return false;
    }

    return !this.isBytebotStore;
  }

  get isActive (): boolean {
    return this.__isActive && !this.isExpired;
  }

  get isExpired (): boolean {
    return this.__isExpired || this.isGracePeriod || this.isSuspended;
  }

  get isExpiringOrExpired (): boolean {
    return this.__isExpired || this.isGracePeriod;
  }

  get isExpiringSoon (): boolean {
    if (!this.expirationDate) {
      return false;
    }

    if (this.isExpired) {
      return false;
    }

    const expDate = new Date(this.expirationDate.getTime());
    expDate.setDate(expDate.getDate() - SUBSCRIPTION_EXPIRATION_SOON_DELTA);

    return expDate < new Date();
  }

  get daysUntilExpire (): number {
    let diff = this.expirationDate.getTime() - Date.now();
    if (diff < 0) {
      diff = 0;
    }
    return Math.ceil(diff / DAY_MILLISECONDS);
  }

  get isBlacklisted (): boolean {
    return this.primaryKey && this.primaryKey.isBlacklisted;
  }

  /**
   *  Returns true if sublicense creation and modification is not available due to suspension or expiration
   */
  get isEditable (): boolean {
    return !this.isExpired && !this.isGracePeriod && !this.isSuspended && !this.isBlacklisted;
  }

  get status (): string {
    if (this.isBlacklisted) {
      return STATUS_BLACKLISTED;
    }
    if (this.isSuspended) {
      return STATUS_SUSPENDED;
    }
    if (this.isExpired) {
      return STATUS_EXPIRED;
    }

    return STATUS_ACTIVE;
  }

  get nextBillingDate (): Date {
    return this.nextBillingAt;
  }

  get expirationDate (): Date {
    return this.mainPeriodEndsAt;
  }

  get activationDate (): Date {
    return this.activatedAt;
  }

  get products (): Array<ProductInfo> {
    return this.licensedProducts.filter((product) => product.isMainResource).map((product) => {
      // Monkey patching as an exceptional case.
      // for PDL subscriptions limit is 100 as unlimited for AppStore, but we need to show 5, so here we go
      if (product.keyName === KEY_NAME_PDL) {
        product.licenses.limit = Math.min(product.licenses.limit, PDL_DISPLAY_LIMIT);
        product.licenses.thisPeriod = Math.min(product.licenses.thisPeriod, PDL_DISPLAY_LIMIT);
      }
      return product;
    });
  }

  get isPerUserSubscription (): boolean {
    return this.hasProduct(PRODUCT_KEY_NAME_PDB_PER_USER);
  }

  get firstProductKeyName (): string {
    return this.products[0] && this.products[0].productKeyName;
  }

  get firstProductName (): string {
    return this.products[0] && this.products[0].productName;
  }

  get firstLicensedProductKeyName (): string {
    return this.products[0] && this.products[0].keyName;
  }

  get firstProductLimit (): number {
    return this.products[0] && this.products[0].licenses.limit;
  }

  get firstProductLimitThisPeriod (): number {
    if (this.isPdfmPermanentPersonal) {
      return this.universalKeys.length;
    }

    const productInfo = this.products[0];
    if (this.hasProduct(PRODUCT_KEY_NAME_PDFC)) {
      if (productInfo && productInfo.licenses.ratio) {
        return productInfo.licenses.thisPeriod / productInfo.licenses.ratio;
      }
    }

    return productInfo && productInfo.licenses.thisPeriod;
  }

  get firstProductUsage (): number {
    return this.products[0] && this.products[0].licenses.usage;
  }

  get firstProductAvailable (): number {
    return this.products[0] && this.products[0].licenses.available;
  }

  get firstProductTotalAvailable (): number {
    return this.products[0] && this.products[0].licenses.totalAvailable;
  }

  get targetServiceName (): Service {
    return this.hasProduct(PRODUCT_KEY_NAME_RAS) ? Service.RasLicense : Service.License;
  }

  getProductUnits (product: ProductInfo): string {
    if (product.productKeyName === PRODUCT_KEY_NAME_RAS) {
      return PRODUCT_UNITS.CONCURRENT_USERS;
    }
    if (product.productKeyName === PRODUCT_KEY_NAME_PMM) {
      if (product.keyName === KEY_NAME_PMM) {
        return PRODUCT_UNITS.MANAGED_COMPUTERS;
      } else {
        return PRODUCT_UNITS.MOBILE_DEVICES;
      }
    }
    if ([PRODUCT_KEY_NAME_PSW, PRODUCT_KEY_NAME_DAAS, PRODUCT_KEY_NAME_PBI].includes(product.productKeyName)) {
      if (product.keyName.includes(CONCURRENT_PRODUCT_MARK)) {
        return PRODUCT_UNITS.CONCURRENT_USERS;
      } else if (product.keyName.includes(NAMED_PRODUCT_MARK)) {
        return PRODUCT_UNITS.NAMED_USERS;
      }
    }
    if (product.productKeyName === PRODUCT_KEY_NAME_PVAD) {
      return PRODUCT_UNITS.NAMED_USERS;
    }
    if (product.productKeyName === PRODUCT_KEY_NAME_PDB_PER_USER) {
      return PRODUCT_UNITS.USERS;
    }
    return PRODUCT_UNITS.COMPUTERS;
  }

  get firstProductUnits (): string {
    return this.getProductUnits(this.products[0]);
  }

  get firstProductModification (): Modification {
    return this.products[0] && this.products[0].licenses.modification;
  }

  get universalKeys (): Array<UniversalKey> {
    return this.__universalKeys.map((item) => {
      return item instanceof UniversalKey ? item : new UniversalKey(item, this);
    });
  }

  set universalKeys (items: Array<UniversalKey>) {
    this.__universalKeys = items.map((item) => {
      item.subscription = this;
      return item;
    });
  }

  get primaryKey (): UniversalKey {
    return this.universalKeys[0];
  }

  get firstLicenseKey (): string {
    return this.universalKeys.length && this.universalKeys[0].licKey;
  }

  get hasContract (): boolean {
    return !!(this.salesForce && this.salesForce.hasContract);
  }

  hasProduct (keyName: string): boolean {
    return this.products.some((item) => item.productKeyName === keyName);
  }

  hasPrimaryProduct (keyName: string): boolean {
    return this.products.some((item) => item.isPrimaryResource && item.productKeyName === keyName);
  }

  get canRenew (): boolean {
    return !this.isSuspended && !this.isPermanent && this.storeId === STORE_NAME_CB;
  }

  get canTurnOnAutorenew (): boolean {
    return !this.isSuspended && !this.isPermanent && this.isSellOptionApplicable && this.storeId === STORE_NAME_CB;
  }

  get canAddLicenses (): boolean {
    return this.canAddLicense;
  }

  get canBuyMoreLicenses (): boolean {
    return !this.isSuspended && (this.canAddLicenses || this.canUpgradeOnline);
  }

  get isWaitingForOfflinePayment (): boolean {
    return this.canRenew && this.products[0].licenses.modification && (this.isUpgrading || this.isDowngrading);
  }

  get durationValue (): number {
    return this.duration.value;
  }

  get durationUnit (): string {
    return this.duration.units;
  }

  get isSellOptionApplicable (): boolean {
    return this.__isSellOptionApplicable && !this.isSuspended;
  }

  get canRenewOnline (): boolean {
    return this.__canRenewOnline && !this.isSuspended;
  }

  get nextBillingCost (): string {
    return this.__nextBillingCost || '';
  }

  get nextBillingCostCurrency (): string {
    return this.__nextBillingCostCurrency || '';
  }

  get isActivePdlSubscription (): boolean {
    return this.status === STATUS_ACTIVE && this.products.some((product) => product.keyName === KEY_NAME_PDL);
  }

  get isMultipleProducts (): boolean {
    return this.products.length > 1;
  }

  get isDifferentProductsAmount (): boolean {
    if (!this.isMultipleProducts) {
      return false;
    }
    return !this.products.map((product) => product.licenses).every((value, _, array) => {
      return array[0].thisPeriod === value.thisPeriod && array[0].objectType === value.objectType;
    });
  }

  get isRasPostpaid (): boolean {
    return this.hasProduct(PRODUCT_KEY_NAME_RAS) && this.isPostPaid;
  }

  get isPdfmPermanentPersonal (): boolean {
    return this.isPermanent && !this.isBusiness && this.firstProductKeyName === PRODUCT_KEY_NAME_PDFM;
  }

  get licensedProducts (): Array<ProductInfo> {
    return this.__licensedProducts || [];
  }

  get isUnlimited (): boolean {
    return !this.isLimited;
  }

  get isPrimary (): boolean {
    return !this.parentUuid;
  }

  get permissionsManageable (): boolean {
    for (const product of [
      PRODUCT_KEY_NAME_PDB,
      PRODUCT_KEY_NAME_RAS,
      PRODUCT_KEY_NAME_PTBB,
      PRODUCT_KEY_NAME_PDB_PER_USER,
      PRODUCT_KEY_NAME_PSW,
      PRODUCT_KEY_NAME_DAAS,
      PRODUCT_KEY_NAME_PBI,
    ]) {
      if (this.hasProduct(product)) {
        return true;
      }
    }

    return false;
  }

  get options (): Array<ProductInfo> {
    return this.licensedProducts.filter((product) => !product.isMainResource);
  }

  get isDynamicSubsetAllowed (): boolean {
    return this.hasProduct(PRODUCT_KEY_NAME_PDB) || this.hasProduct(PRODUCT_KEY_NAME_PDFC) || this.hasProduct(PRODUCT_KEY_NAME_PTBB);
  }

  getProduct (keyName: string): ProductInfo {
    return this.products.find((item) => item.productKeyName === keyName);
  }

  /**
   * Limit of the product for the current period.
   */
  getProductLimitThisPeriod (keyName: string): number {
    const product = this.getProduct(keyName);
    return product && product.licenses.thisPeriod;
  }

  get transactionState (): string {
    return this.storeLastTransactionState || '';
  }

  get viewStatus (): number {
    if (this.isPermanent) {
      return VIEW_STATUS.PERMANENT;
    }

    if (this.isBlacklisted) {
      return VIEW_STATUS.BLACKLISTED;
    }

    if (this.isSuspended) {
      return VIEW_STATUS.SUSPENDED;
    }

    if (this.isBytebotStore) {
      return VIEW_STATUS.BYTEBOT;
    }

    if (this.firstLicensedProductKeyName === KEY_NAME_RAS_IN_APP_TRIAL) {
      return VIEW_STATUS.TRIAL;
    }

    if (this.isActive && !this.isExpiringSoon) {
      return VIEW_STATUS.ACTIVE;
    }

    // Custom store subscriptions
    if (this.storeId === STORE_NAME_CUSTOM) {
      const isRas = this.hasProduct(PRODUCT_KEY_NAME_RAS);
      const isPmm = this.hasProduct(PRODUCT_KEY_NAME_PMM);
      const isPsw = this.hasProduct(PRODUCT_KEY_NAME_PSW);
      const isDaas = this.hasProduct(PRODUCT_KEY_NAME_DAAS);
      const isPbi = this.hasProduct(PRODUCT_KEY_NAME_PBI);
      const isSalesForce = this.isPostPaid || isPmm || isRas || isPsw || isDaas || isPbi;

      if (this.isActive && this.isExpiringSoon) {
        return isSalesForce ? VIEW_STATUS.SF_EXPIRE_SOON : VIEW_STATUS.EXPIRE_SOON;
      }

      return isSalesForce ? VIEW_STATUS.SF_EXPIRED : VIEW_STATUS.EXPIRED;
    }

    // PD Lite or PAX from Apple app store
    if (this.storeId === STORE_NAME_AAS) {
      const isPdl = this.hasProduct(PRODUCT_KEY_NAME_PDL);

      if (this.isActive && this.isExpiringSoon) {
        return isPdl ? VIEW_STATUS.PDL_EXPIRE_SOON : VIEW_STATUS.AAS_EXPIRE_SOON;
      }

      return isPdl ? VIEW_STATUS.PDL_EXPIRED : VIEW_STATUS.AAS_EXPIRED;
    }

    // CB subscriptions
    if (this.storeId === STORE_NAME_CB) {
      if (this.isAutorenewable && this.isActive && this.transactionState === CB_TRANSACTION_STATES.cardExpiring) {
        return VIEW_STATUS.CARD_EXPIRED;
      }

      if (!this.isAutorenewable && this.isActive && this.isExpiringSoon) {
        return VIEW_STATUS.EXPIRE_SOON;
      }

      if (this.isAutorenewable && this.isActive && this.isExpiringSoon) {
        return VIEW_STATUS.WILL_RENEW;
      }

      if (this.isGracePeriod && this.transactionState === CB_TRANSACTION_STATES.renew.offline) {
        return VIEW_STATUS.RENEW_FAILED;
      }

      if (this.isGracePeriod && this.transactionState === CB_TRANSACTION_STATES.renew.decline) {
        return VIEW_STATUS.RENEW_FAILED;
      }

      if (this.isGracePeriod && this.transactionState === CB_TRANSACTION_STATES.renew.refund) {
        return VIEW_STATUS.RENEW_FAILED;
      }

      return VIEW_STATUS.CB_EXPIRED;
    }

    // Google Play store
    if (this.storeId === STORE_NAME_GPS) {
      if (this.isExpiringSoon) {
        return VIEW_STATUS.GPS_EXPIRE_SOON;
      }

      return VIEW_STATUS.GPS_EXPIRED;
    }
  }

  setByteBotStatisticsInfo (data: ByteBotStatistics): void {
    this.bytebotStatistics = data;
  }

  get bytebotUsersAmount (): number {
    if (this.bytebotStatistics) {
      return this.bytebotStatistics.usersAmount;
    }

    return 0;
  }

  get bytebotDevicesAmount (): number {
    if (this.bytebotStatistics) {
      return this.bytebotStatistics.devicesAmount;
    }

    return 0;
  }

  get isBytebotStore (): boolean {
    return this.storeId === STORE_NAME_BYTEBOT;
  }

  get isCustomStore (): boolean {
    return this.storeId === STORE_NAME_CUSTOM;
  }

  get canBeMigrated (): boolean {
    return false;
  }

  get isRasAzmp (): boolean {
    return this.firstProductKeyName === PRODUCT_KEY_NAME_RAS_AZMP;
  }

  get isPswSPLA (): boolean {
    return this.firstProductKeyName === PRODUCT_KEY_NAME_PSW && this.isPostPaid;
  }

  get isRasSPLA (): boolean {
    return this.firstProductKeyName === PRODUCT_KEY_NAME_RAS && this.isPostPaid;
  }

  get isRasAzmpSpla (): boolean {
    return this.isRasAzmp && this.isPostPaid;
  }

  get isDaasSPLA (): boolean {
    return this.firstProductKeyName === PRODUCT_KEY_NAME_DAAS && this.isPostPaid;
  }

  get isPbiSPLA (): boolean {
    return this.firstProductKeyName === PRODUCT_KEY_NAME_PBI && this.isPostPaid;
  }

  get isNeverExpiringPswSPLA (): boolean {
    // TODO: uncomment once CPCLOUD-20194 is done
    return this.isPswSPLA; // && this.mainPeriodEndsAt?.getTime() === new Date('2099-12-31').getTime();
  }

  get isNeverExpiringSPLA (): boolean {
    const isNeverExpiringSPLA = this.mainPeriodEndsAt?.getTime() === new Date('2099-12-31').getTime();
    return this.isNeverExpiringPswSPLA || ((this.isRasSPLA || this.isPbiSPLA || this.isDaasSPLA) && isNeverExpiringSPLA);
  }

  addUniversalKey (key: UniversalKey) {
    const index = this.universalKeys.findIndex((uk) => uk.licKey === key.licKey);
    if (index > -1) {
      this.__universalKeys[index] = key;
    } else {
      this.__universalKeys.push(key);
    }
  }
}
