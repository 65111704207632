

import Vue from 'vue';
import FilterDropdownMy from '@/ui/filterDropdown/index.vue';
import SearchFilterMixin from '@/modules/searchFilterMixin';
import ComponentMixIn from '@/modules/componentMixIn';
import { ActionCallParameters, TableViewColumns } from '@/ui/tableViewConst';
import SSOCompanyUser from '@core/models/ssoCompanyUser';
import { SSOCompanyUsersRequest } from '@/api/sso/ssoCompanyUsersRequest';
import SSOUsersUsageRequest, { RevokeSSOLicenseRequest } from '@/api/sso/ssoUsersUsageRequest';
import TableView from '@/ui/tableView.vue';
import CsvSaver from '@/ui/csvSaver.vue';
import { PRODUCT_KEY_NAME_PDB_PER_USER, PRODUCT_KEY_NAME_PTBB_PER_USER } from '@core/constants/subscriptions';

const ALL_PRODUCTS = 'All';

export default Vue.extend({
  name: 'business-profile-users-sso',
  mixins: [ComponentMixIn, SearchFilterMixin],
  components: { FilterDropdownMy, TableView, CsvSaver },

  props: {
    productFilter: {
      type: String,
      default: ALL_PRODUCTS,
    },
  },

  data () {
    return {
      users: [] as SSOCompanyUser[],
      filters: {} as TableViewFilters,
      table: {
        name: 'businessProfileMembersSSO',
        actions: { id: 'uuid', options: [] as TableViewAction[] },
        sortBy: {
          column: 'uuid',
          ascending: true,
        },
        pagination: {
          perPage: 50,
        },
      },
      csvFileName: 'users.csv',
      currentProduct: this.productFilter,
      productsFilterName: 'products',
      selectedUuids: [] as string[],
    };
  },

  computed: {
    tableColumns (): TableViewColumns {
      return {
        options: [
          { text: this.$t('ID'), value: 'uuid' },
          { text: this.$t('Email'), value: 'email' },
          { text: this.$t('Role'), value: 'role' },
          { text: this.$t('Products'), value: 'totalProducts' },
          { text: this.$t('Devices Licensed'), value: 'totalHosts' },
          { text: this.$t('Registration'), value: 'createdAt', type: 'date' },
          { text: this.$t('Last Contact'), value: 'defaultLastContactedAt', type: 'date' },
        ],
      };
    },
    selectedUsers (): SSOCompanyUser[] {
      return this.selectedUuids.map((uuid) => this.users.find((u) => u.uuid === uuid));
    },
    usersOptions (): { text: string; value: string; filter: Function }[] {
      return [
        { text: this.$t('With product licenses ({count})'), value: 'with-licenses', filter: (user) => Object.keys(user.products).length },
        { text: this.$t('Without product licenses ({count})'), value: 'without-licenses', filter: (user) => !Object.keys(user.products).length },
      ];
    },
    roleOptions (): { text: string; value: string; filter: Function }[] {
      return [
        { text: this.$t('Account Administrator ({count})'), value: 'administrator', filter: (user) => user.isAdmin },
        { text: this.$t('Regular Member ({count})'), value: 'employee', filter: (user) => !user.isAdmin },
      ];
    },
    productsOptions (): { text: string; value: string; filter: Function }[] {
      return [
        { text: this.$t('Parallels Desktop for Mac Business Edition ({count})'), value: PRODUCT_KEY_NAME_PDB_PER_USER, filter: (user) => user.products.hasOwnProperty(PRODUCT_KEY_NAME_PDB_PER_USER) },
        { text: this.$t('Parallels Toolbox ({count})'), value: PRODUCT_KEY_NAME_PTBB_PER_USER, filter: (user) => user.products.hasOwnProperty(PRODUCT_KEY_NAME_PTBB_PER_USER) },
      ];
    },
  },
  mounted () {
    this.load();
  },
  methods: {
    rebuildTableActions () {
      const options: TableViewAction[] = [];
      const selectedUsers = this.selectedUsers.filter((user) => Object.keys(user.products).length);

      options.push({
        value: 'revokeLicenses',
        text: this.$t('Revoke All Product Licenses ({count})').replace('{count}', `${selectedUsers.length}`),
        disabled: !selectedUsers.length,
      });
      options.push({
        value: 'exportCsv',
        text: this.$t('Save List to CSV File ({count})'),
      });
      this.table.actions = { id: 'uuid', options };
    },
    load () {
      // TODO: Add pagination https://parallels.atlassian.net/browse/CPCLOUD-19876
      const usersRequest = new SSOCompanyUsersRequest({ companyUuid: this.$appData.session.businessDomainId });

      this.loading = true;

      this.$api.authorizedCall(usersRequest).then(() => {
        // Since desktop:/api/v1/sso/users and toolbox:/api/v1/sso endpoints use GET to provide list,
        // request for 1500 UIDs will be failed by 414
        // chunking product services requests here, but need to add normal pagination on upper level
        // Should be removed when pagination on upper request added
        // TODO: https://parallels.atlassian.net/browse/CPCLOUD-19884
        const chunkSize = 100;
        const users = usersRequest.getUsers();

        const chunks = [];
        for (let i = 0; i < users.length; i += chunkSize) {
          chunks.push(users.slice(i, i + chunkSize));
        }

        const processChunk = async (chunk) => {
          const usersUsageRequest = new SSOUsersUsageRequest({ users: chunk });
          return await this.$api.authorizedCall(usersUsageRequest);
        };

        const usersUsagesPromises = chunks.map(chunk => processChunk(chunk));

        Promise.all(usersUsagesPromises).then(usersUsagesArrays => {
          const usersUsages = [].concat(...usersUsagesArrays);

          users.forEach((user) => user.fillProductsInfo(usersUsages));

          this.users = users;
          this.$emit('updateMembersAmount', {
            amount: this.productFilter === ALL_PRODUCTS ?
              users.length :
              users.filter((user) => Object.keys(user.products).includes(this.productFilter)).length,
          });
        });
      }).finally(() => {
        this.loading = false;
        if (this.productFilter !== ALL_PRODUCTS) {
          this.filters[this.productsFilterName] = this.productsOptions.find((option) => option.value === this.productFilter).filter;
        }
        this.rebuildTableActions();
      });
    },

    userLastContactedAt (user: SSOCompanyUser): string {
      const lastContactedAt = user.lastContactedAt(this.currentProduct !== ALL_PRODUCTS ? this.currentProduct : undefined);
      return lastContactedAt ? this.formatDateTime(lastContactedAt) : '';
    },

    userLicensedHosts (user: SSOCompanyUser): number {
      if (Object.keys(user.products).length === 0) {
        return 0;
      }
      if (this.currentProduct !== ALL_PRODUCTS) {
        return user.products[this.currentProduct]?.hosts.length || 0;
      } else {
        return Object.keys(user.products).reduce(
          (hostsQuantity: number, key: string) => {
            return hostsQuantity + user.products[key].hosts.length;
          },
          0
        );
      }
    },

    callAction ({ action, items }: ActionCallParameters): void {
      (this[action] as CallbackFunctionType)(items);
    },

    // FIXME CPCLOUD-17383 use CsvSaver included into TableView
    exportCsv (users: SSOCompanyUser[]) {
      (this.$refs.csv as unknown as typeof CsvSaver).save({
        columns: this.tableColumns.options,
        rows: users,
      });
    },

    async revokeLicenses (users: SSOCompanyUser[]) {
      try {
        this.loading = true;
        await this.$api.authorizedCall(
          new RevokeSSOLicenseRequest({
            products: this.productsOptions.map(option => option.value),
            ssoUsersIds: users.map(user => user.uuid),
          })
        );
        users.forEach(user => { user.products = {}; });
        new SSOCompanyUsersRequest({ companyUuid: this.$appData.session.businessDomainId }).dropFullCache();
      } finally {
        this.loading = false;
      }
    },

    currentProductChanged (value: string) {
      this.currentProduct = value;
    },

    onUserClick (member: SSOCompanyUser) {
      this.$emit('showSSOUserDetails', member);
    },
  },
  watch: {
    selectedUuids () {
      this.rebuildTableActions();
    },
  },
});
