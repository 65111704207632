/**
 * File: subscriptionMixIn.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */

import Vue, { VueConstructor } from 'vue';

import { PRODUCT_KEY_NAME_PDFC } from '@core/constants/subscriptions';
import { getSubscriptionName } from '@core/common/subscriptions';
import Subscription from '@/models/subscription';

interface SubscriptionMixin extends Vue {
  subscription: Subscription;
}

/**
 * MixIn for components which displays subscriptions data.
 */
export default (Vue as VueConstructor<SubscriptionMixin>).extend({

  computed: {
    subscriptionName (): string {
      // $t('{product_name} Subscription')
      // $t('{product_name} Permanent License')
      // $t('{product_name} In-product Trial')
      return getSubscriptionName(this.subscription, this.$t.bind(this));
    },

    amountLine (): string {
      if (!this.subscription.products[0]) {
        return '';
      } else if (this.subscription.isPostPaid) {
        if (this.subscription.isRasAzmp) {
          return this.$t('Metered billing');
        }
        return this.$t('SPLA');
      }

      const firstProductLimit = this.subscription.firstProductLimitThisPeriod;
      const units = this.subscription.firstProductUnits;
      const text = `For {limit} ${units}`;

      // $tc('For {limit} managed computers')
      // $tc('For {limit} computers')
      // $tc('For {limit} concurrent users')
      // $tc('For {limit} named users')
      // $tc('For {limit} mobile devices')
      // $tc('For {limit} users')
      return this.$tc(text, firstProductLimit, { limit: firstProductLimit });
    },

    uniqueProducts (): Array<string> {
      const uniqueProducts = [];
      if (this.subscription) {
        this.subscription.products.forEach((p) => {
          if (!uniqueProducts.includes(p.keyName)) {
            uniqueProducts.push(p.keyName);
          }
        });
      }
      return uniqueProducts;
    },
  },

  methods: {

    getSubscriptionName (subscription: Record<string, any>): string {
      return getSubscriptionName(subscription, this.$t.bind(this));
    },

    /**
     * Format resource representation.
     */
    resourceValue (resource: { licenses: { ratio: number; thisPeriod: number; objectType: string; limit: number } }): string {
      if (this.subscription.isUnlimited) {
        return this.$t('Unlimited');
      }

      if (resource.licenses.objectType === 'datetime') {
        return this.$t('Until {date}', { date: this.formatDate(new Date(resource.licenses.limit * 1000)) });
      }

      if (this.subscription.hasProduct(PRODUCT_KEY_NAME_PDFC)) {
        return String(
          resource.licenses.ratio ? resource.licenses.thisPeriod / resource.licenses.ratio : resource.licenses.thisPeriod
        );
      }

      return String(resource.licenses.thisPeriod);
    },

  },
});
