/**
 * File: polyfill.js
 *
 * Copyright:
 * Copyright 2018-2018. Parallels International GmbH. All Rights Reserved.
 *
 * */

/**
 *  Promise.prototype.finally
 */
(function () {
  if (typeof Promise.prototype.finally === 'function') {
    return;
  }
  Promise.prototype.finally = function (fn) {
    return this
      .then(value => this.constructor.resolve(fn()).then(() => value))
      .catch(reason => this.constructor.resolve(fn()).then(() => { throw reason; }));
  };
})();

/**
 *  Element.prototype.classList
 */
(function () {
  // IE8/9, Safari
  if ('classList' in Element.prototype) {
    return;
  }

  // helpers
  var regExp = function (name) {
    return new RegExp('(^| )' + name + '( |$)');
  };
  var forEach = function (list, fn, scope) {
    for (var i = 0; i < list.length; i++) {
      fn.call(scope, list[i]);
    }
  };

  // class list object with basic methods
  function ClassList (element) {
    this.element = element;
  }

  ClassList.prototype = {
    add () {
      forEach(arguments, function (name) {
        if (!this.contains(name)) {
          this.element.className += ' ' + name;
        }
      }, this);
    },
    remove () {
      forEach(arguments, function (name) {
        this.element.className = this.element.className.replace(regExp(name), '');
      }, this);
    },
    toggle (name) {
      return this.contains(name) ? (this.remove(name), false) : (this.add(name), true);
    },
    contains (name) {
      return regExp(name).test(this.element.className);
    }
  };

  Object.defineProperty(Element.prototype, 'classList', {
    get () {
      return new ClassList(this);
    }
  });
})();
