/**
 * File: links.ts
 *
 * Copyright:
 * Copyright 2022-2022. Parallels International GmbH. All Rights Reserved.
 *
 * */

import { getBulaLanguage, getWebsiteLanguage } from '@core/constants/langs';

export const CUSTOMER_EXPERIENCE_PROGRAM_URL = function (isoLocale: string) {
  const language = getWebsiteLanguage(isoLocale);
  return `https://www.parallels.com/${language}/support/pcep/`;
};

export const BULA_TEXT_URL = function (isoLocale: string) {
  const language = getBulaLanguage(isoLocale);
  return `https://www.corel.com/include/bula/${language}.html`;
};
