<copyright>
File: reminder.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

export default {
  name: 'gdpr-reminder',
  props: ['webview', 'newAccount', 'app', 'loading', 'reminderOnly'],
};

</script>

<template lang="pug">

loader(:loading="loading")
  h1(:data-name="$name('title')") {{ $t('Parallels User Account Data Privacy Notice') }}
  p(v-if="!reminderOnly")
    template(v-if="newAccount")
      formatter(:text="$t('Please review the Parallels {policy_link}Privacy Policy{/policy_link} and the {terms_link}Terms of Use{/terms_link}. Accept our terms by clicking the button below (you’re required to do this only once).')")
        a(slot="policy_link", href="https://parallels.com/privacy", slot-scope="s", target="_blank") {{ s.text }}
        a(slot="terms_link", href="https://parallels.com/terms", slot-scope="s", target="_blank") {{ s.text }}
        span.link(slot="settings_link", slot-scope="s", @click="$emit('switch')") {{ s.text }}
    template(v-else)
      formatter(:text="$t('Before continue using Parallels products and services we recommend that you review the Parallels {policy_link}Privacy Policy{/policy_link} and the {terms_link}Terms of Use{/terms_link}.')")
        a(slot="policy_link", href="https://parallels.com/privacy", slot-scope="s", target="_blank") {{ s.text }}
        a(slot="terms_link", href="https://parallels.com/terms", slot-scope="s", target="_blank") {{ s.text }}
        span.link(slot="settings_link", slot-scope="s", @click="$emit('switch')") {{ s.text }}
  h2 {{ $t('How Do We Use Your Data') }}
  p
    formatter(:text="$t('We use the information that we collect and the data that you choose to provide voluntarily strictly for the purposes specified in the Parallels {policy_link}Privacy Policy{/policy_link}. This data may include:')")
      a(slot="policy_link", href="https://parallels.com/privacy", slot-scope="s", target="_blank") {{ s.text }}
  .margin-bottom-2x
    collapsible
      template(slot="title") {{ $t('Parallels account credentials') }}
      template(slot="content")
        p {{ $t('The data includes:') }}
        ul
          li {{ $t('Email and password') }}
        p {{ $t('Any time you log in to your Parallels account in the Parallels application or from a web browser, we use your account email address and password to verify your identity. We never store your password in plain text format. What we do is transform it into an unrecognizable value and then encrypt it. This makes it impossible for anybody to see or guess what your original password in plain text is. ') }}
        p {{ $t('We use your account email address to send you email messages only if you agreed to receive email notifications in reference to your registered Parallels products, critical product updates, product subscriptions, trials, new Parallels products, billing notifications and special offers, and beta versions of Parallels products.') }}
    collapsible
      template(slot="title") {{ $t('Information about your Parallels account') }}
      template(slot="content")
        p {{ $t('The data includes:') }}
        ul
          li {{ $t('Your name (first and last name).') }}
          li {{ $t('Your country (initially detected automatically based on your location; see below).') }}
        p {{ $t('We store the information about your Parallels account if you provided it voluntarily at the time of your account registration or when you edited your account profile. ') }}
        p {{ $t('We use your account profile information (such as your name and country) to properly address you in email messages, display the correct date and time, and use the currency format specific to your country. When you purchase a Parallels product subscription from your account, we share your email, name, and country information with our payment provider (cleverbridge) to tailor the shopping cart specifically for you (i.e. identify your country, language, currency, pre-fill the billing information with your name and email address).') }}
    collapsible
      template(slot="title") {{ $t('Information about your Parallels product licenses') }}
      template(slot="content")
        p {{ $t('The data includes:') }}
        ul
          li {{ $t('History of your Parallels subscriptions and licenses (product type, date of purchase, date of activation, expiration date, renewal date, license key, number of licenses, assigned subscription name).') }}
          li {{ $t('When you activate a license on your computer (Mac or PC), we store the hardware UUID, which is a globally unique identifier assigned to the computer.') }}
        p {{ $t('The information about your product licenses is used for activation of your Parallels products and prevention from using your licenses by someone else. It also allows you to benefit from special offers and discounts that we provide to our loyal customers periodically.') }}
    collapsible
      template(slot="title") {{ $t('Information about using your Parallels account') }}
      template(slot="content")
        p {{ $t('The data includes:') }}
        ul
          li {{ $t('A list of Web browsers and mobile devices from which you logged in to your Parallels account at any time.') }}
          li {{ $t('Location data: IP address, your geographical location (country, state (if applicable), city) detected by the GeoIP service, web browser name and version, mobile device name, model and version, operating system name and version, time zone, virtual browser’s ID (generated by Parallels web app) or virtual mobile device’s hardware ID (generated by Parallels mobile app) to identify the returning web browser/mobile device, last login date and time from each web browser or mobile device, session status (active/not active).') }}
          li {{ $t('The date and time of last login.') }}
          li {{ $t('The date of last account password change.') }}
        p {{ $t('The information about your location, the web browsers and mobile devices that you use to access your Parallels account allows us to provide protection from unauthorized access to your account data.') }}
    collapsible
      template(slot="title") {{ $t('The list of your remote computers (if you’re a Parallels Access user)') }}
      template(slot="content")
        p {{ $t('If you’re using (or used in the past) Parallels Access, we store the list of your remote computers (Mac and PC) registered in Parallels Access. Information about remote computers includes: ') }}
        ul
          li {{ $t('Computer name (retrieved from the OS running on the computer), ') }}
          li {{ $t('Computer model.') }}
        p {{ $t('The information about your mobile devices and remote computers (Mac and PC) registered in Parallels Access is used to allow you access your remote computers using the Parallels Access mobile application or web browser. ') }}
    collapsible
      template(slot="title") {{ $t('General data about your payment transactions') }}
      template(slot="content")
        p {{ $t('When you buy Parallels product licenses and/or subscriptions in the Parallels Online Store, we process your payment transaction information that we receive from our payment provider (cleverbridge). We don’t receive or store information about your credit card number, PayPal account ID, or bank account number. Our payment provider shares with us some of the data that you voluntarily provide at the time of purchase (none of this data can be used to identify your credit card number, PayPal account ID, or bank account number). The data includes:') }}
        ul
          li {{ $t('Your name (first and last).') }}
          li {{ $t('Your language (the one you used in the shopping cart).') }}
          li {{ $t('Your email (if it differs from the account email address).') }}
          li {{ $t('Payment information: currency, payment type (PayPal, bank transfer or credit card type, such as Visa, MasterCard, etc. We never receive or process your credit card information, including the card number, CVV/CVC code, etc.).') }}
          li {{ $t('Your purchase transaction confirmation page URL and invoice (a PDF document URL).') }}
          li {{ $t('The type of the product purchased, the total amount you’ve paid in your currency.') }}
          li {{ $t('Subscription ID.') }}
          li {{ $t('Your purchased subscription cancellation URL and the subscription payment option change URL.') }}
        p {{ $t('When you buy Parallels product licenses and subscriptions in the Parallels Online Store, we use the information about your purchase transactions that we receive from our payment provider (cleverbridge) to provide you with the product license key(s), to allow you to see your purchase history in your Parallels account, purchase additional licenses when needed, manage, renew or cancel your subscriptions. ') }}
        p {{ $t('We never receive or process any information that would allow us to identify a credit card (including the card number, CVV/CVC code, etc.), PayPal or bank account that you used to make a purchase.') }}
        p {{ $t('The information that you provide voluntarily about your payment instrument (credit card number, CVV/CVC code, PayPal, bank account, etc.) is processed by our payment provider (cleverbridge) and is stored on their servers.') }}
    collapsible
      template(slot="title") {{ $t('Technical report data') }}
      template(slot="content")
        p {{ $t('When you’re sending a technical report to us from a Parallels product, we process the technical data that you chose to send voluntarily (when sending a technical report, you can choose not to include all or any of the following categories of data). Technical reports may include the following data:') }}
        ul
          li {{ $t('Product name, version and the license key used for activation (if applicable).') }}
          li {{ $t('Virtual product ID (not associated with any individual).') }}
          li {{ $t('Computer’s hardware ID, hardware configuration and operating system details (including OS name, version, configuration, list of installed and running applications).') }}
          li {{ $t('Virtual machine(s)’s virtual hardware configuration and operating system details (including OS name, version, configuration, list of installed and running applications, dump of the virtual machine memory).') }}
          li {{ $t('Product configuration files (may contain the list of your registered virtual machines) and operation logs.') }}
          li {{ $t('Computer operating system’s log.') }}
          li {{ $t('Screenshots of your computer’s and virtual machine’s desktops.') }}
          li {{ $t('Any file(s) attached by you to the technical data report') }}
          li {{ $t('Email and name (if different from the account email address).') }}
          li {{ $t('Problem description that you provide voluntarily at the time of sending the technical report.') }}
        p {{ $t('The information which you send to us when reporting a problem is used for analysis of the cause of the problem and allows us to improve our products for you.') }}
    collapsible
      template(slot="title") {{ $t('Support data') }}
      template(slot="content")
        p {{ $t('When you contact Parallels support by phone, chat, or email we process your messages and the technical data which you choose to provide voluntarily:') }}
        ul
          li {{ $t('Email and name (if different from the account email address).') }}
          li {{ $t('The text of your messages to Parallels Support, including any emails, names, and other information that you provided when contacting the Support.') }}
          li {{ $t('Any documents, screenshots, and other files that you’ve attached to your emails or otherwise provided to Parallels support engineers.') }}
          li {{ $t('The phone number that was used to contact the support (if any).') }}
        p {{ $t('The data you provide when contacting Parallels support is used by our Support team to track your requests and resolve your problems as soon as possible.') }}
    collapsible
      template(slot="title") {{ $t('Parallels Forum’s data') }}
      template(slot="content")
        p {{ $t('If you use Parallels support forums, we process the personal information about you if you choose to provide it voluntarily:') }}
        ul
          li {{ $t('Nickname you use on Parallels forums.') }}
          li {{ $t('Signature you use on the forums (if available).') }}
        p {{ $t('We also collect and store the following information:') }}
        ul
          li {{ $t('History of Parallels forums you’ve visited.') }}
          li {{ $t('History of forum threads you watched.') }}
          li {{ $t('History of your posts on the Parallels forums (texts of the posts including any attached files)') }}
          li {{ $t('History of you private conversations with users of the Parallels forums (texts of the conversations including any attached files)') }}
          li {{ $t('List of the people you follow.') }}
          li {{ $t('List of the people you ignore.') }}
        p {{ $t('Any information that you choose to provide us with about yourself on the Parallels support forum is used strictly for representing you in the Parallels product forums. The information we collect in addition to it (as specified above) is intended to improve your user experience with Parallels forums.') }}
  h2 {{ $t('Timeframe for Keeping Data') }}
  p {{ $t('We process and store your Personal Data as long as necessary for the fulfilment of our contractual or legal obligations. Thus, we store the data as long as our contractual relationship with you as our customer obliges us to do so and for the duration of your account with us. If you delete your account or request us to do so, all your Personal Data will be deleted, unless its further processing is necessary for the preservation of evidence or the prevention of legal claims from becoming time-barred.') }}
  .last(v-if="!reminderOnly")
    template(v-if="newAccount")
      h2 {{ $t('We Need Your Consent') }}
      p
        formatter(:text="$t('To proceed with using Parallels products and services, please accept the Parallels {policy_link}Privacy Policy{/policy_link} and the {terms_link}Terms of Use{/terms_link} and confirm that you consent with the data processing rules described herein.')")
          a(
            slot="policy_link",
            href="https://parallels.com/privacy",
            slot-scope="s",
            target="_blank",
            :data-name="$name('link-privacy')"
          ) {{ s.text }}
          a(
            slot="terms_link",
            href="https://parallels.com/terms",
            slot-scope="s",
            target="_blank",
            :data-name="$name('link-terms')"
          ) {{ s.text }}
  .buttons(v-if="!reminderOnly")
    btn(
      :class="{ 'block-xs': !webview }",
      @click="$emit('accept')",
      data-qa="button-accept-data-privacy-notice",
      :data-name="$name('button-accept')"
    )
      template(v-if="newAccount") {{ $t('I Accept') }}
      template(v-else) {{ $t('Got It') }}
    btn(
      :class="{ 'block-xs': !webview }",
      color="transparent",
      @click="$emit('back')",
      data-qa="button-back-data-privacy-notice",
      :data-name="$name('button-back')"
    ) &larr; {{ $t('Back') }}

</template>

<style scoped lang="sass">

.page, .webview
  .buttons
    +clearfix
  button
    float: right
    margin-left: $grid-step * 2
    &:last-child
      float: left
      margin: 0

.webview
  .buttons
    padding-left: $grid-step * 2
    padding-right: $grid-step * 2

.page
  button
    +phone
      display: block
      margin: 0 0 $grid-step 0
      float: none

.toolbox.win
  .buttons
    padding-right: $grid-step * 3

.toolbox
  h1
    word-break: break-word

ul
  padding-left: $grid-step * 2

</style>
