
import Locale from '@/plugins/locale';
import StepAbstract from './stepAbstract.vue';
import CompanyUsersRequest from '@/api/companyUsersRequest';

export default StepAbstract.extend({
  name: 'step-configure-backup-login',
  props: {
    title: {
      default: () => { return Locale.i18n.t('Configure backup login'); },
    },
    recommended: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      backupLogin: this.settings.backupLogin,
      admins: [],
    };
  },
  mounted () {
    this.loadAdmins();
  },
  methods: {
    async loadAdmins () {
      const companyUsersRequest = new CompanyUsersRequest({}, this.$appData.session.businessDomainId);

      this.loading = true;

      try {
        await this.$api.authorizedCall(companyUsersRequest);
        this.admins = companyUsersRequest.getUsers().filter(user => user.isAdmin && !user.isInvited);
      } finally {
        this.loading = false;
      }
    },
    onExpanded (val) {
      if (val && !this.done) {
        this.emitDone(true);
      }
      this.$emit('expanded', val);
    },
    onSave () {
      this.loading = true;
      this.$emit('save', this.stepName, { backupLogin: this.backupLogin });
    },
  },
  computed: {
    backupLoginOptions (): { value: boolean; text: string }[] {
      return this.admins.map((item) => { return { value: item.uuid, text: item.email }; });
    },
  },
});
