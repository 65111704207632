/**
 * File: validators.ts
 *
 * Copyright:
 * Copyright © 2018 Parallels International GmbH. All rights reserved.
 *
 * */

// @ts-ignore
import { helpers, required, minLength, maxLength, sameAs, requiredUnless, requiredIf } from 'vuelidate/lib/validators';
import { getCountries, STATES } from '../constants/geo';

const ZIP_REGEXP = /^([0-9a-zA-Z]*)$/;
const PHONE_REGEXP = /^([0-9a-zA-Z()+ \-*#]*)$/;
const LIC_KEY_REGEXP = /^[0-9a-z]{6}-?[0-9a-z]{6}-?[0-9a-z]{6}-?[0-9a-z]{6}-?[0-9a-z]{6}$/i;
const PAX_CODE_REGEXP = /^[0-9a-z]{4}-?[0-9a-z]{4}-?[0-9a-z]{4}$/i;
const OTP_REGEXP = /^[0-9A-Z]{6,8}$/;
const EMAIL_REGEXP = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
const DOMAIN_NAME_REGEX = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
const SKU_REGEXP = /^\w+([-_]\w+)*$/;

// https://jira.prls.net/browse/CPCLOUD-17244
// TODO: check if issue is fixed in new version of vuelidate
export function emailValidator (email) {
  return !email || EMAIL_REGEXP.test(email);
}

export function optional (validatorConfig) {
  let config = Object.assign({}, validatorConfig);
  delete config.required;
  return config;
}

export const name = {
  maxLength: maxLength(150)
};

export const password = {
  required,
  maxLength: maxLength(128),
  minLength: minLength(6)
};

export const password2 = {
  sameAsPassword: sameAs('password')
};

export const email = {
  required,
  email: emailValidator
};

export const companyName = {
  required,
  maxLength: maxLength(300)
};

export const zip = {
  required,
  maxLength: maxLength(225),
  zip: function (zip) {
    return !zip || ZIP_REGEXP.test(zip);
  }
};

export const phone = {
  required,
  maxLength: maxLength(40),
  phone: function (phone) {
    return !phone || PHONE_REGEXP.test(phone);
  }
};

export const city = {
  required,
  maxLength: maxLength(225)
};

export const address = {
  required,
  maxLength: maxLength(225)
};

export function isCountryValid (country) {
  return country && getCountries().hasOwnProperty(country.toUpperCase());
}

export function isStateRequired (country) {
  return isCountryValid(country) && STATES.hasOwnProperty(country.toUpperCase());
}

export function isStateValid (state, country) {
  if (!isStateRequired(country)) {
    return true;
  } else {
    let states = STATES[country.toUpperCase()] || {};
    return state && states.hasOwnProperty(state.toUpperCase());
  }
}

export const country = {
  required,
  country: function (country) {
    return !country || isCountryValid(country);
  }
};

export const state = {

  required: function (state) {
    // @ts-ignore
    let country = this.country || (this.form && this.form.country);
    if (!isStateRequired(country)) {
      // true means value is valid
      return true;
    }
    return !!state;
  },

  state: function (state) {
    if (!state) {
      // this will be validated by 'required'
      return true;
    }
    // @ts-ignore
    let country = this.country || (this.form && this.form.country);
    return isStateValid(state, country);
  }

};

export const acceptTerms = {
  required (value) {
    // @ts-ignore
    return !!(this.consent.required || value);
  }
};

export function clearLicenseKey (licKey) {
  return (licKey || '').replace(/[\s\t\n\r]/g, '');
}

export function isSkuStringValid (value) {
  return !value || SKU_REGEXP.test(value);
}

export function isLicenseKeyValid (value) {
  return !value || LIC_KEY_REGEXP.test(value);
}

export function isPaxPromoCode (value) {
  return !value || PAX_CODE_REGEXP.test(value);
}

export function isDomainNameValid (value) {
  return value && DOMAIN_NAME_REGEX.test(value);
}

export function positive (value) {
  value = Number(value);
  return Number.isInteger(value) && value > 0;
}

export function isNumber (value) {
  return !isNaN(parseFloat(value)) && !isNaN(value - 0);
}

export function isValidJson (metaDataString) {
  try {
    let json = JSON.parse(metaDataString);
    if (!(json && json.constructor === Object)) {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
}

export const oneTimePassword = {
  required,
  otpFormat: function (otp) {
    return !otp || OTP_REGEXP.test(otp);
  }
};

export {
  required,
  requiredUnless,
  requiredIf,
  minLength,
  maxLength,
  sameAs,
  helpers
};
