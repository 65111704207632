
import Vue from 'vue';

import { PRODUCT_NAME_PDB } from '@core/constants/subscriptions';

import ComponentMixIn from '@/modules/componentMixIn';
import ProductCardMixIn from './productCardMixIn';
import {
  BUSINESS_PROFILE_SCOPE,
  DESKTOP_DOWNLOADS_PAGE,
  DESKTOP_SSO_USERS_PAGE,
  DESKTOP_SUBSCRIPTION_DETAILS_PAGE,
  SSO_USERS_PAGE
} from '@/routes/constants';
import Subscription from '@/models/subscription';
import { SSOCompanyUsersRequest } from '@/api/sso/ssoCompanyUsersRequest';

export default Vue.extend({
  name: 'product-card-pdb-per-user',

  mixins: [ComponentMixIn, ProductCardMixIn],

  data: function () {
    return {
      companyUsersLoading: true,
      companyUsersQuantity: null as number,
      DESKTOP_DOWNLOADS_PAGE,
      DESKTOP_SUBSCRIPTION_DETAILS_PAGE,
      DESKTOP_SSO_USERS_PAGE,
      BUSINESS_PROFILE_SCOPE,
      SSO_USERS_PAGE,
    };
  },

  created: function () {
    const ssoCompanyUsersRequest = new SSOCompanyUsersRequest({ companyUuid: this.$appData.session.businessDomainId });

    this.authorizedCall(ssoCompanyUsersRequest, 'companyUsersLoading')
      .then((data) => {
        this.companyUsersQuantity = data.users.length;
      });
  },

  computed: {
    productName (): string {
      return this.$t(PRODUCT_NAME_PDB);
    },
    subscription (): Subscription {
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      return this.subscriptions.find((s: Subscription) => (s.isPerUserSubscription));
    },
    subscriptionItemText (): string {
      if (this.subscription.isExpired) {
        return this.$t('Subscription Expired');
      } else if (this.subscription.isExpiringSoon) {
        return this.$t('Subscription Expires');
      } else {
        return this.$t('Subscription Renewal');
      }
    },
  },
});
