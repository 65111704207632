/**
 * File: ssoCompanyUser.ts
 *
 * Copyright:
 * Copyright 2021-2022. Parallels International GmbH. All Rights Reserved.
 *
 */

import CoreModel from '@core/common/model';
import { Dictionary } from '@core/common/types';
import { AutomaticLicensesRevocationPeriod, PRODUCT_KEY_NAME_PDB_PER_USER, PRODUCT_KEY_NAME_PTBB_PER_USER } from '@core/constants/subscriptions';
import ConfigurationProfile from '@core/models/configurationProfile';

export class SSOHostInfo extends CoreModel {
  hwId: string;
  macAddresses: string;
  osName: string;
  buildNumber: string;
  activated: boolean;
  activatedAt: Date;
  lastConnectionAt: Date;
  ipAddresses: string;
  ip6Addresses: string;
  customName: string;
  hostName?: string;
  serialNumber?: string;
  userName?: string;
  __notes?: string;
  subscription?: { isExpired: boolean, isExpiringSoon: boolean, isSubset: boolean, uuid: string };
  __configurationProfile?: ConfigurationProfile;
  generalConfigurationProfile?: ConfigurationProfile;
  licKey: string;
  subscriptionUuid?: string;
  products?: number;

  set name (value: string) {
    this.customName = value;
  }

  get name (): string {
    return this.customName || this.hostName;
  }

  set notes (value: string) {
    this.__notes = value;
  }

  get notes (): string {
    return this.__notes;
  }

  get lastConnectionDate (): Date {
    return this.lastConnectionAt;
  }

  get activatedDate (): Date {
    return this.activatedAt;
  }

  get configurationProfile (): ConfigurationProfile {
    return this.__configurationProfile;
  }

  set configurationProfile (value: ConfigurationProfile) {
    this.__configurationProfile = value;
    if (!this.generalConfigurationProfile) {
      this.generalConfigurationProfile = value;
    }
  }

  get generalConfigurationProfileId (): number {
    return this.generalConfigurationProfile && this.generalConfigurationProfile.id;
  }
}

export class SSOUserUsage extends CoreModel {
  uuid: string;
  pdPerUserLicenseRevocationPeriod: AutomaticLicensesRevocationPeriod;
  ptbPerUserLicenseRevocationPeriod: AutomaticLicensesRevocationPeriod;
  pdHosts: SSOHostInfo[];
  ptbHosts: SSOHostInfo[];
}

export class SSOProductInfo {
  hosts: SSOHostInfo[];
  perUserLicensesRevocationPeriod: AutomaticLicensesRevocationPeriod;
  __lastContactedAt: Date;
  __firstActivationAt: Date;

  constructor (perUserLicensesRevocationPeriod: AutomaticLicensesRevocationPeriod, hosts: SSOHostInfo[] = []) {
    this.perUserLicensesRevocationPeriod = perUserLicensesRevocationPeriod;
    this.hosts = hosts;
  }

  lastContactedAt (host?: SSOHostInfo): Date {
    if (host) {
      return this.hosts.find((h) => h.hwId === host.hwId)?.lastConnectionAt;
    }

    if (!this.__lastContactedAt) {
      this.__lastContactedAt = this.hosts.reduce(
        (currLastContactedAt: Date, h: SSOHostInfo) => {
          if (!currLastContactedAt) {
            return h.lastConnectionAt;
          }
          return currLastContactedAt > h.lastConnectionAt ? currLastContactedAt : h.lastConnectionAt;
        },
        undefined
      );
    }

    return this.__lastContactedAt;
  }

  firstActivationAt (host?: SSOHostInfo): Date {
    if (host) {
      return this.hosts.find((h) => h.hwId === host.hwId)?.activatedAt;
    }

    if (!this.__firstActivationAt) {
      this.__firstActivationAt = this.hosts.reduce(
        (currFirstActivationAt: Date, host: SSOHostInfo) => {
          if (!currFirstActivationAt) {
            return host.activatedAt;
          }
          return currFirstActivationAt < host.activatedAt ? currFirstActivationAt : host.activatedAt;
        },
        undefined
      );
    }

    return this.__firstActivationAt;
  }
}

export default class SSOCompanyUser extends CoreModel {
  uuid: string;
  userId: number;
  email: string;
  name: string;
  isAdmin: boolean;
  notes?: string;
  products?: Dictionary<SSOProductInfo>;
  hosts: SSOHostInfo[];
  createdAt: Date;

  constructor (data) {
    super(data);
    this.products = {};
    this.hosts = [];
  }

  get totalHosts (): number {
    return this.hosts.length;
  }

  get totalProducts (): number {
    return Object.keys(this.products).length;
  }

  get defaultLastContactedAt (): Date {
    return this.lastContactedAt();
  }

  lastContactedAt (product?: string, host?: SSOHostInfo): Date {
    if (Object.keys(this.products).length === 0) {
      return undefined;
    }
    if (product) {
      return this.products[product]?.lastContactedAt(host);
    } else {
      return Object.keys(this.products).reduce(
        (currLastContactedAt: Date, key: string) => {
          const val: Date = this.products[key].lastContactedAt(host);
          if (currLastContactedAt === undefined) {
            return val;
          }
          if (val === undefined) {
            return currLastContactedAt;
          }
          return currLastContactedAt > val ? currLastContactedAt : val;
        },
        undefined
      );
    }
  }

  firstActivationAt (product?: string, host?: SSOHostInfo): Date {
    if (Object.keys(this.products).length === 0) {
      return undefined;
    }
    if (product) {
      return this.products[product]?.firstActivationAt(host);
    } else {
      return Object.keys(this.products).reduce(
        (currFirstActivationAt: Date, key: string) => {
          const val: Date = this.products[key].firstActivationAt(host);
          if (currFirstActivationAt === undefined) {
            return val;
          }
          if (val === undefined) {
            return currFirstActivationAt;
          }
          return currFirstActivationAt < val ? currFirstActivationAt : val;
        },
        undefined
      );
    }
  }

  fillProductsInfo (usersUsages: SSOUserUsage[]) {
    const usage = usersUsages.find(obj => { return obj.uuid === this.uuid; });

    const allHosts = usage.pdHosts.concat(usage.ptbHosts);
    this.hosts = allHosts.filter((item) => {
      return Object.is(allHosts.find((elem) => elem.hwId === item.hwId), item);
    });

    this.products = {};
    if (usage.pdHosts.length) {
      this.products[PRODUCT_KEY_NAME_PDB_PER_USER] = new SSOProductInfo(usage.pdPerUserLicenseRevocationPeriod, usage.pdHosts);
    }

    if (usage.ptbHosts.length) {
      this.products[PRODUCT_KEY_NAME_PTBB_PER_USER] = new SSOProductInfo(usage.ptbPerUserLicenseRevocationPeriod, usage.ptbHosts);
    }
  }

  get roleName (): string {
    return this.isAdmin ? 'Account Admin' : 'Regular Member';
  }
}
