<copyright>
File: index.vue

Copyright:
Copyright © 2023 Parallels International GmbH. All rights reserved.
</copyright>

<script>

import heightObserverMixin from './heightObserverMixin';
import CardInfo from './cardInfo';
import { Card } from '@parallels/ui-kit';

const MAX_RIBBON_WIDTH = 110;

export default {
  name: 'card-my',
  props: {
    type: {
      /**
       *  Available values: `plain`, `table`, `form`, `subscription`, `subscription-details`, `info`, `product`
       */
      type: String,
      default () {
        return 'plain';
      },
    },
    /**
     *  Key to track other cards height with the same key
     */
    equalTo: {
      type: String,
    },
    /**
     *  Default class name for `info` slot
     */
    infoClassName: {
      type: String,
      default: 'height-auto-xs-sm',
    },
    /**
     *  Card icon: `pd, ras, pmm, pd_lite or toolbox`
     */
    icon: {
      type: String,
    },
    /**
     *  Card padding in grid steps
     */
    padding: {
      type: [Number, String],
      default: 2,
    },
    /**
     *  Flag to add hover
     */
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardInfoEqualTo () {
      if (this.equalTo) {
        return `${this.equalTo}-cardinfo`;
      } else {
        return undefined;
      }
    },
    cardClass () {
      return `padding-${this.padding}x`;
    },
  },
  watch: {
    '$i18n.locale': function () {
      this.$nextTick(() => {
        this.setRibbonFontSize();
      });
    },
  },
  mounted () {
    this.setRibbonFontSize();
  },
  methods: {
    setRibbonFontSize () {
      if (this.$slots.ribbon) {
        const el = this.$el.querySelector('.ribbon');
        let size = 15;

        el.style.fontSize = '';

        while (el.clientWidth > MAX_RIBBON_WIDTH) {
          el.style.fontSize = `${--size}px`;
        }
      }
    },
  },
  mixins: [heightObserverMixin],
  components: {
    Card,
    CardInfo,
  },
};

</script>

<template lang="pug">

card(:clickable="clickable", @click="$emit('click')")

  .subscription(v-if="type === 'subscription'")
    h4.title(v-if="$slots.title")
      slot(name="title")
    .about(v-if="$slots.about", :class="{ limited: $slots.type, 'no-margin': !$slots.status }")
      slot(name="about")
    .status(v-if="$slots.status")
      slot(name="status")
    .type(v-if="$slots.type")
      slot(name="type")

  .subscription-details(v-if="type === 'subscription-details'")
    .row
      .col-sm-6.col-xs-12
        h4.margin-bottom-2x.title(v-if="$slots.title")
          slot(name="title")
      .col-sm-6.col-xs-12.margin-bottom-xs.text-right-sm.text-right-md.text-ellipsis
        slot(name="state")
    CardInfo.height-auto-xs(v-if="$slots.info", :equalTo="cardInfoEqualTo", :class="infoClassName")
      slot(name="info")
    .menu(v-if="$slots.menu")
      slot(name="menu")

  .product(v-if="type === 'product'")
    .icon.hidden-xs(v-if="icon", :class="[ icon ]")
    .overflow-hidden.margin-bottom
      h4.title(v-if="$slots.title")
        slot(name="title")
      .about.text-ellipsis.text-muted(v-if="$slots.about")
        slot(name="about")
      slot(name="actions")
        | &nbsp;
    CardInfo(v-if="$slots.info", :equalTo="cardInfoEqualTo", :class="infoClassName")
      slot(name="info")
    .menu(v-if="$slots.menu")
      slot(name="menu")

  .table-view(v-if="type === 'table'")
    .row.margin-bottom-2x(v-if="$slots.title || $slots.actions")
      .col-sm-6.col-xs-12
        h4.title(v-if="$slots.title")
          slot(name="title")
      .col-sm-6.col-xs-12.text-right
        slot(name="actions")
    .table
      slot(name="data")

  .form(v-if="type === 'form'")
    .margin-top-2x.margin-bottom-4x
      slot(name="title")
    .row
      .col-md-10.col-md-offset-1.col-xs-12
        slot(name="content")

  .info(v-if="type === 'info'")
    .row.margin-bottom
      h4.title(v-if="$slots.title", :class="{ 'col-md-6': $slots.subtitle }").col-xs-12
        slot(name="title")
      div(v-if="$slots.subtitle").col-md-6.col-xs-12.text-muted
        slot(name="subtitle").pull-right
    CardInfo(v-if="$slots.info", :equalTo="cardInfoEqualTo", :class="infoClassName")
      slot(name="info")
    .menu.clearfix(v-if="$slots.menu")
      slot(name="menu")

  .plain(v-if="type === 'plain'")
    slot

  .action(v-if="type == 'action'")
    .margin-bottom-2x(v-if="$slots.image")
      slot(name="image")
    .notice(v-if="$slots.notice")
      slot(name="notice")
    h2.margin-bottom.text-center(v-if="$slots.title")
      slot(name="title")
    .text-center.margin-bottom-3x(v-if="$slots.content")
      slot(name="content")
    .clearfix(v-if="$slots.buttons")
      slot(name="buttons")

  .support-product(v-if="type === 'supportProduct'")
    .icon(v-if="icon", :class="[ icon ]")
    .text-center
      slot

  .ribbon(v-if="$slots.ribbon")
    span
      slot(name="ribbon")

</template>

<style scoped lang="sass">

.subscription
  position: relative
  .title
    margin-bottom: $vertical-step
  .about
    font-size: $small-font-size
    +phone
      margin-bottom: $vertical-step
    &.limited
      +desktop
        max-width: 60%
  .type
    +phone-and-tablet-only
      margin-top: $vertical-step * 2
    +desktop
      // TODO: move to theme
      position: absolute
      right: 0
      top: 50%
      margin: 0
      max-width: 40%
      transform: translateY(-50%)
      -ms-transform: translateY(-50%)

.subscription-details
  padding-top: $vertical-step

.product
  padding-top: $vertical-step
  .icon
    float: left
    margin-right: $gutter-width * 2
  .about
    margin-bottom: $vertical-step

.about
  margin-bottom: $vertical-step * 2

.action
  .notice
    margin: 0 $gutter-width * 6 $vertical-step * 3 $gutter-width * 6
    text-align: center
    color: $steel-grey
    opacity: 0.5

.support-product
  .icon
    margin: auto auto $grid-step auto

.table-view
  .title
    line-height: $vertical-step * 3

$icon-size: 66px
$icon-root: $image-root + '/products'

@each $icon in pd, ras, pmm, pd_lite, toolbox, cvt, hdt, dvt, dnst, myacc, pvad, psw, daas, pbi
  .icon.#{ $icon }
    width: $icon-size
    height: $icon-size
    background: url('#{ $icon-root }/#{ $icon }.png') no-repeat
    background-size: $icon-size
    +retina
      background-image: url('#{ $icon-root }/#{ $icon }@2x.png')

.icon.pdfc
  width: $icon-size
  height: $icon-size
  background: url('#{ $icon-root }/pdfc.svg') no-repeat
  background-size: $icon-size

$ribbon-color: #D72637

.ribbon
  position: absolute
  right: 0
  top: 0
  color: $white
  transform: translateX(29.29%)
  text-transform: uppercase
  span
    display: block
    padding: 5px
    transform: rotateZ(45deg)
    transform-origin: left top
    background: $ribbon-color
    &::before, &::after
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: $ribbon-color
      content: ''
      z-index: -1
      transform-origin: left top
    &::before
      transform: skewX(-45deg)
    &::after
      transform: skewX(45deg)

</style>
