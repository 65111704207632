

import Vue from 'vue';

import BannerNotification from '@/ui/bannerNotification/index.vue';
import { FEATURE_RAS_TECHNICAL_PREVIEW_BANNER } from '@core/constants/features';
import { RAS_BETA_PAGE } from '@/routes/constants';
import bannerNotificationMixin from '@/ui/bannerNotification/bannerNotificationMixin';

const KEY_NAME = 'ras-tp-notification-hidden';

export default Vue.extend({
  name: 'ras-technical-preview-notification',
  components: {
    BannerNotification,
  },
  mixins: [bannerNotificationMixin],
  data () {
    return {
      featureKey: FEATURE_RAS_TECHNICAL_PREVIEW_BANNER,
      storageKey: KEY_NAME,
      RAS_BETA_PAGE,
    };
  },
});

