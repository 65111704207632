

import RasDeactivateHostsRequest from '@/api/rasDeactivateHostsRequest';
import RasRemoveHostsRequest from '@/api/rasRemoveHostsRequest';
import CsvSaver from '@/ui/csvSaver.vue';
import Vue, { PropType } from 'vue';
import RasHost from '@/models/rasHost.ts';
import { RAS_SUBPAGE_SUBSCRIPTION_DETAILS } from '@/routes/constants';

// TODO: move table interfaces to TableView class CPCLOUD-16423
interface TableItem {
  value: string;
  key: string;
  name: string;
  parent: boolean;
}

interface TableViewAction {
  value: string;
  text: string;
  disabled?: boolean;
}

interface TableViewActions {
  id: string;
  options: TableViewAction[];
}

interface TableViewColumn {
  text: string;
  value: string;
  type?: string;
  options?: TableItem[];
  multiple?: boolean;
}

interface TableViewColumns {
  options: TableViewColumn[];
}

interface TableFilters {
  [key: string]: Function;
}

export default Vue.extend({
  name: 'farms-table',
  components: {
    CsvSaver,
  },
  props: {
    isSpla: {
      type: Boolean,
      required: true,
    },
    farms: {
      type: Array as PropType<RasHost[]>,
      required: true,
    },
    filters: {
      type: Object as PropType<TableFilters>,
      default () {
        return {};
      },
    },
    totalPeakUsage: {
      type: Number,
      default: 0,
    },
    deactivationEnabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    total: {
      type: Number,
    },
  },
  data () {
    return {
      RAS_SUBPAGE_SUBSCRIPTION_DETAILS,
      table: {
        columns: [] as Record<string, any>[],
        selectedIds: [] as string[],
      },
      sortBy: {
        column: 'name',
        ascending: true,
      },
      filteredFarmsCount: undefined,
    };
  },
  methods: {
    callAction (params): void {
      const method = params.action;
      this[method](params.items);
    },
    onFarmClick (farm): void {
      this.$emit('farmClick', farm);
    },
    showConfirmDeactivationModal (): void {
      this.$modal.show('confirmDeactivationModal');
    },
    showConfirmRemovingModal (): void {
      this.$modal.show('confirmRemovingModal');
    },
    changeColumns (params): void {
      this.table.columns = params.columns;
    },
    exportCsv (farms): void {
      // @ts-ignore
      this.$refs.csv.save({
        columns: this.table.columns,
        rows: farms,
      });
    },
    getUniversalKeys (keyPropertyName, subscriptionPropertyName): TableItem[] {
      // Find unique items
      const subscriptions = this.farms
        .filter((farm, i, farms) => {
          return farm[keyPropertyName] && farms.findIndex((f) => f[keyPropertyName] === farm[keyPropertyName]) === i;
        })
        .map((farm) => {
          return farm[subscriptionPropertyName];
        })
        .filter(Boolean);
      return subscriptions.filter((subscription) => {
        // Looking for parent subscriptions or subsets without parent in the list
        return !subscription?.parentUuid || !subscriptions.filter((s) => s.uuid === subscription.parentUuid).length;
      }).reduce((keys, key) => {
        // Sort keys as [ parent1, child1, child1, parent2, child2 ]
        keys.push(key);
        return keys.concat(subscriptions.filter((s) => s.parentUuid === key.uuid));
      }, []).map((subscription) => {
        return {
          value: subscription.licKey,
          key: subscription.licKey,
          name: subscription.name,
          parent: !!subscription.parentUuid,
        };
      });
    },
    deactivateFarms (): void {
      const hwIds = this.selectedActiveFarms.map((farm) => farm.hwId);
      const request = new RasDeactivateHostsRequest({ hwIds });

      this.$api.authorizedCall(request).then(() => {
        this.$emit('hostsDeactivated');
        this.$toast.show({
          text: this.$tc('{amount} farms deactivated.', hwIds.length, { amount: hwIds.length }),
        });
      });
    },
    removeFarms (): void {
      const hwIds = this.selectedFarms.map((farm) => farm.hwId);
      const request = new RasRemoveHostsRequest({ hwIds });

      this.$api.authorizedCall(request).then((data) => {
        const
          removed = data.removed;
        const ignored = data.ignored;

        if (removed) {
          this.$toast.show({ text: this.$tc('{amount} farms have been removed.', removed, { amount: removed }), color: 'green' });
        }
        if (ignored) {
          this.$toast.show({ text: this.$tc('{amount} farms could not be removed because they are still active. Please deactivate them first.', ignored, { amount: ignored }), color: 'yellow' });
        }

        this.$emit('hostsRemoved', hwIds);
      });
    },
    setFarmsCount (params) {
      this.filteredFarmsCount = params.items.length;
    },
  },
  computed: {
    universalKeys (): TableItem[] {
      return this.getUniversalKeys('universalKey', 'subscription');
    },
    usedUniversalKeys (): TableItem[] {
      return this.getUniversalKeys('usedUniversalKey', 'historySubscription');
    },
    tableName (): string {
      return this.isSpla ? 'postpaidFarms' : 'prepaidFarms';
    },
    selectedFarms (): RasHost[] {
      return this.table.selectedIds.map((id) => this.farms.find((farm) => farm.hwId === id)).filter((farm) => farm);
    },
    selectedActiveFarms (): RasHost[] {
      return this.selectedFarms.filter((farm) => farm.active);
    },
    selectedDeactivatedFarms (): RasHost[] {
      return this.selectedFarms.filter((farm) => !farm.active);
    },
    actions (): TableViewActions {
      const actions: TableViewAction[] = [];

      if (this.deactivationEnabled) {
        actions.push({
          value: 'showConfirmDeactivationModal',
          text: this.$t('Deactivate ({count})', { count: this.selectedActiveFarms.length }),
          disabled: !this.selectedActiveFarms.length,
        });
      }

      if (!this.isSpla) {
        actions.push({
          value: 'showConfirmRemovingModal',
          text: this.$t('Remove ({count})', { count: this.selectedFarms.length }),
          disabled: !this.selectedFarms.length,
        });
      }

      actions.push({
        value: 'exportCsv',
        text: this.$t('Save List to CSV File ({count})'),
      });

      return {
        id: 'hwId',
        options: actions,
      };
    },
    columns (): TableViewColumns {
      let options: TableViewColumn[];

      if (this.isSpla) {
        options = [
          { text: this.$t('Farm Name'), value: 'name' },
          { text: this.$t('Current Status'), value: 'statusNamePostpaid' },
          { text: this.$t('Farm ID'), value: 'farmGuid' },
          { text: this.$t('Server ID'), value: 'hwId' },
          { text: this.$t('App Version'), value: 'productVersion' },
          { text: this.$t('Activation Date'), value: 'activationDate', type: 'date' },
          { text: this.$t('Last Report Received'), value: 'postpaidLastReportDate', type: 'date' },
          { text: this.$t('Peak Usage'), value: 'peak' },
          { text: this.$t('Current Activation Key'), value: 'universalKeyPostpaid', options: this.universalKeys, multiple: true },
          { text: this.$t('Used Activation Key'), value: 'usedUniversalKeyPostpaid', options: this.usedUniversalKeys, multiple: true },
          { text: this.$t('Notes'), value: 'notes' },
        ];
      } else {
        options = [
          { text: this.$t('Farm Name'), value: 'name' },
          { text: this.$t('Activation Key'), value: 'universalKeyPrepaid', options: this.universalKeys, multiple: true },
          { text: this.$t('Status'), value: 'statusNamePrepaid' },
          { text: this.$t('Farm ID'), value: 'farmGuid' },
          { text: this.$t('Server ID'), value: 'hwId' },
          { text: this.$t('App Version'), value: 'productVersion' },
          { text: this.$t('Activation Date'), value: 'activationDate', type: 'date' },
          { text: this.$t('Last Report Date'), value: 'prepaidLastReportDate', type: 'date' },
          { text: this.$t('Last Daily Usage'), value: 'daily' },
          { text: this.$t('Notes'), value: 'notes' },
        ];
      }
      return {
        options,
      };
    },
  },
});

