/**
 * File: size.ts
 *
 * Copyright:
 * Copyright 2018-2021. Parallels International GmbH. All Rights Reserved.
 *
 **/

export const formatBytes = function (bytes: number, decimals = 2): string {
  if (!bytes) {
    return $t('{size} B', { size: 0 });
  }

  let k = 1024,
    units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k)),
    size = parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)),
    text = `{size} ${units[i]}`;
  // $t('{size} B')
  // $t('{size} KB')
  // $t('{size} MB')
  // $t('{size} GB')
  // $t('{size} TB')
  // $t('{size} PB')
  // $t('{size} EB')
  // $t('{size} ZB')
  // $t('{size} YB')
  return $t(text, { size });
};
