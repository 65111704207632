
import Vue, { PropType } from 'vue';

import {
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PDFC_PURCHASE,
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_KEY_NAME_PTB,
  PRODUCT_KEY_NAME_PTBB,
  PRODUCT_KEY_NAME_PTB_HDT,
  PRODUCT_KEY_NAME_PTB_DNST,
  PRODUCT_KEY_NAME_PTB_DVT,
  PRODUCT_KEY_NAME_PTB_CVT,
  PRODUCT_KEY_NAME_PMM,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDL,
  PRODUCT_KEY_NAME_PDB_PER_USER,
  PRODUCT_KEY_NAME_PVAD,
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_DAAS,
  PRODUCT_KEY_NAME_PBI,
  PRODUCT_KEY_NAME_RAS_AZMP
} from '@core/constants/subscriptions';
import ProductCardsFactory from '@/models/productCard';
import productCardPDB from './productCard/productCardPDB.vue';
import productCardPDBPerUser from './productCard/productCardPDBPerUser.vue';
import productCardRAS from './productCard/productCardRAS.vue';
import productCardPMM from './productCard/productCardPMM.vue';
import productCardPTB from './productCard/productCardPTB.vue';
import productCardPDFM from './productCard/productCardPDFM.vue';
import productCardPDL from './productCard/productCardPDL.vue';
import productCardPVAD from './productCard/productCardPVAD.vue';
import productCardPSW from './productCard/productCardPSW.vue';
import productCardPDFC from './productCard/productCardPDFC/index.vue';
import productCardPDFCPurchase from './productCard/productCardPDFC/purchase.vue';
import productCardDaas from '@/modules/home/dashboard/productCards/productCard/productCardDaas.vue';
import productCardPBI from '@/modules/home/dashboard/productCards/productCard/productCardPBI.vue';
import productCardRasAzmp from '@/modules/home/dashboard/productCards/productCard/productCardRasAzmp.vue';
import LegacyLicense from '@/models/legacyLicense';
import Subscription from '@/models/subscription';
import Session from '@/models/session';

interface ProductCardData {
  productKeyName: string;
}

export default Vue.extend({
  name: 'product-cards',

  components: {
    productCardPDB,
    productCardPDBPerUser,
    productCardRAS,
    productCardPMM,
    productCardPTB,
    productCardPDFM,
    productCardPDL,
    productCardPDFC,
    productCardPDFCPurchase,
    productCardPVAD,
    productCardPSW,
    productCardDaas,
    productCardPBI,
    productCardRasAzmp,
  },

  data () {
    return {
      productPriority: [
        PRODUCT_KEY_NAME_PDFM,
        PRODUCT_KEY_NAME_PDB,
        PRODUCT_KEY_NAME_PDFC,
        PRODUCT_KEY_NAME_PDFC_PURCHASE,
        PRODUCT_KEY_NAME_PDL,
        PRODUCT_KEY_NAME_RAS,
        PRODUCT_KEY_NAME_PMM,
        PRODUCT_KEY_NAME_PTBB,
        PRODUCT_KEY_NAME_PTB,
        PRODUCT_KEY_NAME_PTB_HDT,
        PRODUCT_KEY_NAME_PTB_DNST,
        PRODUCT_KEY_NAME_PTB_DVT,
        PRODUCT_KEY_NAME_PTB_CVT,
        PRODUCT_KEY_NAME_PVAD,
        PRODUCT_KEY_NAME_PSW,
        PRODUCT_KEY_NAME_DAAS,
        PRODUCT_KEY_NAME_PBI,
        PRODUCT_KEY_NAME_RAS_AZMP,
      ],
    };
  },

  props: {
    subscriptions: {
      type: Array as PropType<Subscription[]>,
      required: true,
    },
    session: {
      type: Object as PropType<Session>,
      required: true,
    },
    personalMode: {
      type: Boolean,
      required: true,
    },
    isPdfcPurchaseAccessible: {
      type: Boolean,
      default: false,
    },
  },

  methods: {

    getCardClass (productKeyName): string {
      const classNames = {
        [PRODUCT_KEY_NAME_PDB]: 'productCardPDB',
        [PRODUCT_KEY_NAME_PDB_PER_USER]: 'productCardPDBPerUser',
        [PRODUCT_KEY_NAME_PDFC]: 'productCardPDFC',
        [PRODUCT_KEY_NAME_PDFC_PURCHASE]: 'productCardPDFCPurchase',
        [PRODUCT_KEY_NAME_RAS]: 'productCardRAS',
        [PRODUCT_KEY_NAME_PTB]: 'productCardPTB',
        [PRODUCT_KEY_NAME_PTBB]: 'productCardPTB',
        [PRODUCT_KEY_NAME_PTB_HDT]: 'productCardPTB',
        [PRODUCT_KEY_NAME_PTB_DNST]: 'productCardPTB',
        [PRODUCT_KEY_NAME_PTB_DVT]: 'productCardPTB',
        [PRODUCT_KEY_NAME_PTB_CVT]: 'productCardPTB',
        [PRODUCT_KEY_NAME_PMM]: 'productCardPMM',
        [PRODUCT_KEY_NAME_PDFM]: 'productCardPDFM',
        [PRODUCT_KEY_NAME_PDL]: 'productCardPDL',
        [PRODUCT_KEY_NAME_PVAD]: 'productCardPVAD',
        [PRODUCT_KEY_NAME_PSW]: 'productCardPSW',
        [PRODUCT_KEY_NAME_DAAS]: 'productCardDaas',
        [PRODUCT_KEY_NAME_PBI]: 'productCardPBI',
        [PRODUCT_KEY_NAME_RAS_AZMP]: 'productCardRasAzmp',
      };
      return classNames[productKeyName];
    },

    /**
     * Returns Object where key is Product Key Name and value is Object with products details.
     * @param {Array<Subscription>} subscriptions
     * @returns {Object<String, Object>}
     */
    buildCardsData (subscriptions): ProductCardData[] {
      const flatten = (arrays) => [].concat(...arrays);
      return flatten(subscriptions.map(ProductCardsFactory.fromSubscription)).reduce((cards, current) => {
        if (!cards.hasOwnProperty(current.productKeyName)) {
          cards[current.productKeyName] = current;
        } else {
          cards[current.productKeyName].merge(current);
        }
        return cards;
      }, {});
    },
  },

  computed: {
    cards (): ProductCardData[] {
      const cards = Object.values(this.buildCardsData(this.subscriptions))
        .filter(card => this.getCardClass(card.productKeyName)); // Filters the cards data that have a card class

      if (this.isPdfcPurchaseAccessible) {
        cards.push(ProductCardsFactory.addPdfcPuchaseLinkCard());
      }

      cards.sort((a, b) => {
        if (this.productPriority.indexOf(a.productKeyName) < this.productPriority.indexOf(b.productKeyName)) {
          return -1;
        }
        if (this.productPriority.indexOf(a.productKeyName) > this.productPriority.indexOf(b.productKeyName)) {
          return 1;
        }
        return 0;
      });

      return cards;
    },

    rows (): Array<ProductCardData>[] {
      const cards = this.cards;

      return cards.reduce((rows, card, i) => {
        const n = Math.floor(i / 2);
        rows[n] = rows[n] || [];
        rows[n].push(card);
        return rows;
      }, []);
    },

    subscriptionCount (): number {
      return this.subscriptions.filter(subscription => {
        return !(subscription instanceof LegacyLicense) && !subscription.isTrial;
      }).length;
    },
  },
});

