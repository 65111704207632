import { render, staticRenderFns } from "./intro.vue?vue&type=template&id=1d7f18d8&scoped=true&lang=pug"
import script from "./intro.vue?vue&type=script&lang=js"
export * from "./intro.vue?vue&type=script&lang=js"
import style0 from "./intro.vue?vue&type=style&index=0&id=1d7f18d8&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d7f18d8",
  null
  
)

/* custom blocks */
import block0 from "./intro.vue?vue&type=custom&index=0&blockType=copyright"
if (typeof block0 === 'function') block0(component)

export default component.exports