
import Locale from '@/plugins/locale';
import StepAbstract from './stepAbstract.vue';

export default StepAbstract.extend({
  name: 'step-register-parallels-enterprise-app',
  props: {
    title: {
      default: () => { return Locale.i18n.t('Register Parallels enterprise app'); },
    },
    mandatory: {
      type: Boolean,
      default: true,
    },
  },
});
