
import Locale from '@/plugins/locale';
import { copyToClipboard } from '@core/common/utils';
import BusinessProfileDomainsSSO from '../businessProfileDomainsSSO/businessProfileDomainsSSO.vue';
import StepAbstract from './stepAbstract.vue';

export default StepAbstract.extend({
  name: 'step-configure-organizations-domains',
  components: { BusinessProfileDomainsSSO },
  props: {
    title: {
      default: () => { return Locale.i18n.t('Configure organization’s domain(s)'); },
    },
    mandatory: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    copyToClipboard (value: string): void {
      copyToClipboard(value);
      const text = this.$t('The TXT record has been copied to the clipboard.');
      this.$toast.show({ text });
    },
    onDomainsLoaded (domains) {
      // @ts-ignore FIXME https://jira.prls.net/browse/CPCLOUD-16310
      this.problem = this.$refs.domainsTable.domains.some((domain) => domain.isFailed === true);
      this.$emit('loaded', domains);
    },
  },
});
