/**
 * File: cache.js
 *
 * Copyright:
 * Copyright 2017-2021. Parallels International GmbH. All Rights Reserved.
 *
 * */

import { Dictionary } from '../common/types';

// TTL in seconds for cached data. 0 = no cache
export const CACHE_TTL = 60;

type NameSpace = Dictionary<any>;

export default class Cache {
  static cache: Cache;

  store: Dictionary<NameSpace>;

  constructor () {
    if (Cache.cache === undefined) {
      Cache.cache = this;
      this.store = {};
    }
    return Cache.cache;
  }

  getNameSpace (nameSpace: string): NameSpace {
    if (this.store[nameSpace] === undefined) {
      this.store[nameSpace] = {};
    }
    return this.store[nameSpace];
  }

  getCache (nameSpace: string, id: string): any {
    let record = this.getNameSpace(nameSpace)[id];
    if (!record || !CACHE_TTL) {
      return null;
    }
    let time = record.time || 0;
    let data = record.data || null;
    if (Date.now() > time + CACHE_TTL * 1000) {
      return null;
    }
    if (data) {
      data = JSON.parse(JSON.stringify(data));
    }
    return data;
  }

  saveCache (nameSpace: string, id: string, data?: any) {
    if (data) {
      data = JSON.parse(JSON.stringify(data));
    }
    this.getNameSpace(nameSpace)[id] = { data, time: Date.now() };
  }

  dropCache (nameSpace?: string, id?: string) {
    if (nameSpace === undefined) {
      this.store = {};
    } else if (id === undefined) {
      this.dropNameSpace(nameSpace);
    } else {
      this.getNameSpace(nameSpace)[id] = undefined;
    }
  }

  dropNameSpace (nameSpace: string) {
    this.store[nameSpace] = undefined;
  }
};
