
import Locale from '@/plugins/locale';
import { maxLength, required } from '@core/common/validators';
import StepAbstract from './stepAbstract.vue';
import { SsoMetadataUploadRequest, SsoMetadataDownloadRequest } from '@/api/sso/ssoMetadataRequests';
import FileUploader from '@/ui/file-uploader/index.vue';

export default StepAbstract.extend({
  name: 'step-configure-saml-integration',
  components: { FileUploader },
  props: {
    title: {
      default: () => { return Locale.i18n.t('Configure SAML integration'); },
    },
    mandatory: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      form: Object.assign({}, this.settings),
      domainId: this.$appData.session.businessDomainId,
      fileUploaderShown: false,
      uploadedFileName: null as string,
      wrongXMLUploaded: false,
    };
  },
  validations: {
    form: {
      entityId: {
        required,
        maxLength: maxLength(255),
      },
      ssoUrl: {
        required,
        maxLength: maxLength(255),
      },
      certificate: {
        required,
        maxLength: maxLength(3000),
      },
    },
  },
  methods: {
    onSaveClick (): void {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.$emit('save', this.stepName, Object.assign({}, this.form));
    },
    async uploadXML (files: File[]): Promise<void> {
      const request = new SsoMetadataUploadRequest({ domainId: this.domainId, file: files[0] });
      this.loading = true;
      try {
        const response = await this.$api.authorizedCall(request);
        this.uploadedFileName = files[0].name;
        this.wrongXMLUploaded = false;
        this.form.entityId = response.entity_id;
        this.form.ssoUrl = response.sso_url;
        this.form.certificate = response.certificate;
      } catch (e) {
        this.wrongXMLUploaded = true;
      } finally {
        this.loading = false;
      }
    },
    async downloadXML (): Promise<void> {
      const request = new SsoMetadataDownloadRequest({ domainId: this.domainId });
      this.loading = true;
      try {
        const response = await this.$api.authorizedCall(request);
        const blob = new Blob([Buffer.from(response.data, 'base64')]);
        const fileName = 'sp_sso_metadata.xml';
        if (navigator.msSaveOrOpenBlob) {
          // For Edge and IE
          navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          const objUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = objUrl;
          link.download = fileName;
          link.click();
          // For Firefox, it is necessary to delay revoking the ObjectURL.
          setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
        }
      } catch (_) {
        this.$toast.show({ text: this.$t('Can’t download file'), color: 'red' });
      } finally {
        this.loading = false;
      }
    },
  },
});
