/**
 * File: getSupportPage.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 * */
import Vue from 'vue';

import RtTicketsRequest from '@/api/rt/rtTicketsRequest';
import SubscriptionsRequest from '@/api/subscriptionsRequest';
import CombinedApiRequest from '@core/api/combinedApiRequest';
import AllProductsPerUserRequest from '@/api/allProductsPerUserRequest';
import RasAllProductsPerUserRequest from '@/api/rasAllProductsPerUserRequest';
import PdLicensesRequest from '@/api/pdLicensesRequest';
import ListBusinessDomainsRequest, { ListBusinessDomainsRequestResponse } from '@/api/listBusinessDomainsRequest';
import GetDomainsWithProductRequest, { GetDomainsWithProductRequestResponse } from '@/api/getDomainsWithProductRequest';

import LegacyLicense from '@/models/legacyLicense';
import Subscription from '@/models/subscription';

import ComponentMixIn from '@/modules/componentMixIn';
import CreateTicketMixin from '@/modules/support/content/createTicketMixin';

import Step1SelectProduct from './content/step1SelectProduct.vue';
import Step2SelectSupportType from './content/step2SelectSupportType.vue';
import Step3ProblemDescription from './content/step3ProblemDescription.vue';
import Step4ChoiceSupportMethod from './content/step4ChoiceSupportMethod.vue';
import UnregisteredPdfcSupportContent from './content/unregisteredPdfcSupportContent.vue';
import {
  prodKeyToName,
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_MY_ACCOUNT,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDB_PER_USER,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDFM_PRO,
  PRODUCT_KEY_NAME_PDL,
  PRODUCT_KEY_NAME_PDPRO,
  PRODUCT_KEY_NAME_PTB,
  PRODUCT_KEY_NAME_PTB_CVT,
  PRODUCT_KEY_NAME_PTB_DNST,
  PRODUCT_KEY_NAME_PTB_DVT,
  PRODUCT_KEY_NAME_PTB_HDT,
  PRODUCT_KEY_NAME_PTBB,
  PRODUCT_KEY_NAME_PVAD,
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_KEY_NAME_DAAS,
  PRODUCT_KEY_NAME_PBI,
  PRODUCT_KEY_NAME_RAS_AZMP
} from '@core/constants/subscriptions';
import { FEATURE_PDFC_PRODUCT } from '@core/constants/features';
import { TicketStatus } from '@/models/ticket';
import { GET_SUPPORT_PAGE } from '@/routes/constants';
import { Environment } from '@/models/initialConfig';

import { getMilitaryTimezone } from '@core/common/datetime';
import { getContactSource, SUPPORT_TYPE_LICENSE } from '@/modules/support/constants';
import { TICKET_FIELD_CONTACT_SOURCE, TICKET_FIELD_SUPPORT_TYPE, TICKET_FIELD_TIMEZONE } from '@/api/rt/constants';
import { KB_LINK } from '@core/constants/links';
import BreadcrumbsMixin from '@/modules/breadcrumbsMixin';
import Breadcrumbs from '@/ui-kit-fork/components/breadcrumbs/index.vue';

const BUSINESS_PRODUCTS = [
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDB_PER_USER,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PTBB,
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_DAAS,
  PRODUCT_KEY_NAME_PBI,
];

// sorted by priority
const SUPPORTED_PRODUCTS = [
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDB_PER_USER,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_DAAS,
  PRODUCT_KEY_NAME_PBI,
  PRODUCT_KEY_NAME_PDL,
  PRODUCT_KEY_NAME_PTB,
  PRODUCT_KEY_NAME_MY_ACCOUNT,
];

type ProductsData = {
  business: {
    [key: number]: string[];
  };
  personal: {
    [key: number]: string[];
  };
};

export default Vue.extend({
  name: 'support-page',

  mixins: [
    ComponentMixIn,
    CreateTicketMixin,
    BreadcrumbsMixin,
  ],

  components: {
    Step1SelectProduct,
    Step2SelectSupportType,
    Step3ProblemDescription,
    Step4ChoiceSupportMethod,
    UnregisteredPdfcSupportContent,
    Breadcrumbs, // TODO https://parallels.atlassian.net/browse/CPCLOUD-19846
  },

  props: {
    product: {
      type: String,
    },
    supportType: {
      type: String,
    },
    action: {
      type: String,
    },

  },

  data () {
    return {
      initLoading: true,
      loading: true,
      loadingTickets: false,
      ticketsAmount: 0,
      hasOpenedTickets: false,
      openedTicketsChecked: false,
      subscriptions: [] as Subscription[],
      pdLegacy: [] as LegacyLicense[],
      products: {} as ProductsData,
      error: false,
      GET_SUPPORT_PAGE,
      business_domains: [] as ListBusinessDomainsRequestResponse['domains'],
      invitations: [] as ListBusinessDomainsRequestResponse['domains'],
      signed_domains_ids_data: '',
      domains_with_pdfc: [] as number[],
    };
  },

  async mounted () {
    if (this.$appData.session.isCurrentBusinessUser(this.$appData.userInDomain)) {
      const request = new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_BUSINESS });
      await this.$api.authorizedCall(request, { retry: false });
      this.subscriptions = request.getSubscriptions();
      await this.initProducts();
    } else {
      await this.initProducts();
    }
    await this.loadBusinessDomains();
    if (this.signed_domains_ids_data) {
      await this.loadDomainsWithPdfc();
    }
    this.loadTicketsAmount();
  },

  watch: {
    product: {
      handler (product) {
        if (product) {
          this.checkForOpenedTicket();
        } else {
          this.hasOpenedTickets = false;
          this.openedTicketsChecked = false;
        }
      },
      immediate: true,
    },

    isCurrentBusinessUser: function (val) {
      this.loading = true;
      const request = new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_BUSINESS });
      this.$api.authorizedCall(request, { retry: false })
        .then(() => {
          this.subscriptions = request.getSubscriptions();
          this.loadTicketsAmount();
          this.loading = false;
        });
    },

    invitationsWithPdfc: {
      handler (val) {
        if (val.length && this.showUnregisteredPdfcSupportContent) {
          this.createPdfcSupportTicket(this.invitationsWithPdfc.map((invitation) => invitation.id));
        }
      },
    },

    '$appData.userInDomain': function (userInDomain) {
      this.loading = true;
      const request = new SubscriptionsRequest({ type: SubscriptionsRequest.TYPE_BUSINESS });
      this.$api.authorizedCall(request, { retry: false })
        .then(() => {
          this.subscriptions = request.getSubscriptions();
          this.loadTicketsAmount();
          this.loading = false;
        });
    },
  },

  methods: {
    isBusinessProduct () {
      const inBusinessContext = this.$appData.session.isCurrentBusinessUser(this.$appData.userInDomain);
      if (this.productForSupport === undefined) {
        return inBusinessContext;
      }

      if (BUSINESS_PRODUCTS.includes(this.productForSupport)) {
        return !!this.$appData.session.getCurrentBusinessUser();
      }
      return inBusinessContext;
    },

    loadTicketsAmount () {
      this.loadingTickets = true;
      new RtTicketsRequest().dropFullCache();
      this.$api.callRt(new RtTicketsRequest({ amountOnly: true }), this.isBusinessProduct())
        .then((data) => {
          this.ticketsAmount = data.amount || 0;
        })
        .finally(() => {
          this.loadingTickets = false;
        });
    },

    async checkForOpenedTicket () {
      if (this.openedTicketsChecked) {
        return;
      }
      new RtTicketsRequest().dropFullCache();
      const request = new RtTicketsRequest();
      await this.$api.callRt(request, this.isBusinessProduct());
      const data = request.data;
      this.openedTicketsChecked = true;

      this.hasOpenedTickets = data.tickets.some((ticket) => {
        const status = ticket.fields.status;
        return status !== TicketStatus.closed && status !== TicketStatus.inEngineering &&
          ticket.fields.productKey === this.productForSupport;
      });
    },

    async initProducts () {
      new AllProductsPerUserRequest().dropCache();
      new RasAllProductsPerUserRequest().dropCache();
      this.initLoading = true;

      const personalDomainId = this.$appData.session.personalDomainId;
      const combinedRequest = new CombinedApiRequest({ integrity: false });

      const allBusinessDomains = this.$appData.session.getBusinessDomains();
      const allBizDomainsIds = allBusinessDomains.map((domain) => { return domain.companyUuid; });
      let allDomains = allBizDomainsIds;
      if (personalDomainId) {
        allDomains = allDomains.concat(personalDomainId);
      }

      combinedRequest.addRequest('ls', new AllProductsPerUserRequest({ domainIds: allDomains }));
      combinedRequest.addRequest('rasls', new RasAllProductsPerUserRequest({ domainIds: allDomains }));
      combinedRequest.addRequest('pdlegacy', new PdLicensesRequest());

      await this.$api.authorizedCall(combinedRequest);

      let personal = {};
      let business = {};

      const lsProducts = combinedRequest.getRequest('ls').data || {};
      if (personalDomainId) {
        if (lsProducts && Object.keys(lsProducts.personal).length) {
          personal = lsProducts.personal;
        } else {
          personal[personalDomainId] = [];
        }

        personal[personalDomainId] = personal[personalDomainId].concat(PRODUCT_KEY_NAME_MY_ACCOUNT);
        this.pdLegacy = combinedRequest.getRequest('pdlegacy').data;
        if (this.pdLegacy.length && !personal[personalDomainId].includes(PRODUCT_KEY_NAME_PDFM)) {
          personal[personalDomainId].push(PRODUCT_KEY_NAME_PDFM);
        }
      }

      if (lsProducts && Object.keys(lsProducts.business).length > 0) {
        business = lsProducts.business;
      }

      const rasLsProducts = combinedRequest.getRequest('rasls').data || {};
      if (rasLsProducts && Object.keys(rasLsProducts.business).length > 0) {
        const businessDomainIds = Object.keys(rasLsProducts.business);
        for (const businessDomainId of businessDomainIds) {
          if (rasLsProducts.business[businessDomainId].length > 0) {
            business[businessDomainId] = (business[businessDomainId] || []).concat(rasLsProducts.business[businessDomainId]);
          }
        }
      }

      this.products = { personal, business };

      if (this.isUnknownProduct) {
        this.error = true;
      }

      this.initLoading = false;
    },
    async loadBusinessDomains () {
      this.loading = true;

      const request = new ListBusinessDomainsRequest({ get_signed_domains_for: 'ls' });

      try {
        const data = await this.$api.authorizedCall(request);
        this.business_domains = data.domains.filter((domain) => !domain.is_invite);
        this.invitations = data.domains.filter((domain) => domain.is_invite);
        this.signed_domains_ids_data = data.signed_domains_ids;
      } finally {
        this.loading = false;
      }
    },
    async loadDomainsWithPdfc () {
      this.loading = true;

      const request = new GetDomainsWithProductRequest({ domainIds: this.signed_domains_ids_data, product: PRODUCT_KEY_NAME_PDFC });
      try {
        const data: GetDomainsWithProductRequestResponse = await this.$api.authorizedCall(request);
        this.domains_with_pdfc = data.results.map((el) => el.id);
      } finally {
        this.loading = false;
      }
    },

    createPdfcSupportTicket (domainIds) {
      const env = this.$appData.config?.environment;
      let saEnv = 'sa';
      if (env !== Environment.Production) {
        saEnv = `${saEnv}-${env}`;
      }
      const saUrl = `https://${saEnv}.prls.net`;
      const saCompanyUrls = [];
      for (const domainId of domainIds) {
        const saCompanyUrl = `${saUrl}/companies/${domainId}`;
        saCompanyUrls.push(saCompanyUrl);
      }

      const saCompanyUrlsStr = saCompanyUrls.join(' , ');

      const userEmail = this.$appData.session.email;

      const queueId = env === Environment.Production ? 8405 : env === Environment.Dev00 ? 8421 : 8318;

      const description = `
      Dear Parallels Support,

      User ${userEmail} wants to submit a support ticket on Parallels Desktop for Chrome OS but they’re not a member of any business account having the product license registered.
      More insights: based on the user’s domain analysis, there are a matching business accounts
      ${saCompanyUrlsStr} with the Parallels Desktop for Chrome OS subscription registered in them.

      With best regards,
      Parallels MA Team`;

      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      this.resetCreatedTicketCache();
      // @ts-ignore FIXME: https://jira.prls.net/browse/CPCLOUD-16280
      return this.createOrUpdateTicket({
        product: 182,
        [TICKET_FIELD_SUPPORT_TYPE]: SUPPORT_TYPE_LICENSE,
        case_origin: 'email',
        severity: 3,
        country: this.$appData.session.country,
        [TICKET_FIELD_TIMEZONE]: getMilitaryTimezone(),
        [TICKET_FIELD_CONTACT_SOURCE]: getContactSource(),
        summary: `User ${userEmail} reports a problem submitting a support request on PD for Chrome OS`,
        description,
        queue_id: queueId,
      });
    },

    trackClick (event, name) {
      if (!event.target.href) {
        return;
      }

      this.$trackEvent({
        category: 'Support',
        name,
        source: document.title,
      });
    },
  },

  computed: {
    productForSupport (): string {
      return this.product === PRODUCT_KEY_NAME_RAS_AZMP ? PRODUCT_KEY_NAME_RAS : this.product;
    },

    subpages (): MenuItem[] {
      return [
        {
          text: this.$t('Knowledge Base'),
          click: () => {
            const kbId = this.kbLinkId ? `?qprod=${this.kbLinkId}` : '';
            const locale = this.$appData.session.locale.toLowerCase();
            const map = {
              de_de: 'de',
              it_it: 'it',
              fr_fr: 'fr',
              ru_ru: 'ru',
              es_es: 'es',
              zh_cn: 'cn',
              zh_tw: 'hk',
              ja_jp: 'jp',
              ko_kr: 'kr',
            };
            const kbLocale = `${map[locale] ? map[locale] + '/' : ''}`;
            window.open(`${KB_LINK}${kbLocale}${kbId}`, '_blank');
          },
        },
        {
          text: this.$t('Forums'),
          click: () => { window.open(this.forumLink, '_blank'); },
        },
        {
          text: this.ticketsAmount ? this.$t('My Tickets ({amount})', { amount: this.ticketsAmount }) : this.$t('My Tickets'),
          link: { name: 'myTickets' },
        }];
    },

    supportTypeName (): string {
      if (this.supportType) {
        if (this.supportType === 'technical') {
          return $t('Technical Issues');
        } else if (this.supportType === 'license') {
          if (this.productForSupport === 'myacc') {
            return $t('Search for Solution');
          } else {
            return $t('License & Subscription');
          }
        } else if (this.supportType === 'presales') {
          return $t('Pre-sales & Purchase');
        }
        return this.supportType;
      }
      return '';
    },

    isCurrentBusinessUser (): boolean {
      return this.$appData.session.isCurrentBusinessUser(this.$appData.userInDomain);
    },

    isRegularMember (): boolean {
      return this.$appData.session.isCurrentRegularMember(this.$appData.userInDomain);
    },

    hasSubscriptions (): boolean {
      return this.subscriptions.length > 0;
    },

    isSupportable (): boolean {
      return !(this.isRegularMember && !this.hasSubscriptions);
    },

    supportedProducts (): string[] {
      return SUPPORTED_PRODUCTS;
    },

    hasPdfc (): boolean {
      const businessProducts = this.products.business || {};
      const hasProduct = Object.keys(businessProducts).some((domainId) => businessProducts[domainId].includes(PRODUCT_KEY_NAME_PDFC));

      return hasProduct || this.$appData.session.isFeatureAccessible(FEATURE_PDFC_PRODUCT);
    },

    isUnknownProduct (): boolean {
      return this.productForSupport && !prodKeyToName(this.productForSupport);
    },

    showUnregisteredPdfcSupportContent (): boolean {
      return !!(this.productForSupport && this.productForSupport === PRODUCT_KEY_NAME_PDFC && !this.businessDomainsWithPdfc.length);
    },

    invitationsWithPdfc (): ListBusinessDomainsRequestResponse['domains'] {
      return this.invitations.filter((invitation) => this.domains_with_pdfc.includes(invitation.id));
    },

    businessDomainsWithPdfc (): ListBusinessDomainsRequestResponse['domains'] {
      return this.business_domains.filter((domain) => this.domains_with_pdfc.includes(domain.id));
    },

    forumLink (): string {
      switch (this.productForSupport) {
        case PRODUCT_KEY_NAME_PDB:
        case PRODUCT_KEY_NAME_PDFM:
        case PRODUCT_KEY_NAME_PDL:
        case PRODUCT_KEY_NAME_PDPRO:
          return 'https://forum.parallels.com/#parallels-desktop-for-mac.771';
        case PRODUCT_KEY_NAME_PTB:
        case PRODUCT_KEY_NAME_PTB_HDT:
        case PRODUCT_KEY_NAME_PTB_DNST:
        case PRODUCT_KEY_NAME_PTB_DVT:
        case PRODUCT_KEY_NAME_PTB_CVT:
          return 'https://forum.parallels.com/#parallels-toolbox.764';
        case PRODUCT_KEY_NAME_RAS:
          return 'https://forum.parallels.com/#parallels-remote-application-server.745';
        default:
          return 'https://forum.parallels.com';
      }
    },

    kbLinkId (): number | undefined {
      switch (this.productForSupport) {
        case PRODUCT_KEY_NAME_PDFC:
          return 3004;
        case PRODUCT_KEY_NAME_PSW:
          return 3106;
        case PRODUCT_KEY_NAME_PVAD:
          return 3132;
        case PRODUCT_KEY_NAME_PDB_PER_USER:
        case PRODUCT_KEY_NAME_PDB:
          return 2537;
        case PRODUCT_KEY_NAME_PDFM:
          return 86;
        case PRODUCT_KEY_NAME_PDFM_PRO:
          return 2558;
        case PRODUCT_KEY_NAME_PDL:
          return 2570;
        case PRODUCT_KEY_NAME_RAS:
          return 2552;
        case PRODUCT_KEY_NAME_PTB:
          return 2571;
        case PRODUCT_KEY_NAME_PTBB:
          return 2585;
        case PRODUCT_KEY_NAME_MY_ACCOUNT:
          return 3003;
        default:
          return undefined;
      }
    },
  },
});
