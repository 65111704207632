/**
 * File: constants.ts
 *
 * Copyright:
 * Copyright © 2023 Parallels International GmbH. All rights reserved.
 *
 **/
import {
  PRODUCT_KEY_NAME_PSW,
  PRODUCT_KEY_NAME_PDB,
  PRODUCT_KEY_NAME_PDB_PER_USER,
  PRODUCT_KEY_NAME_PDFC,
  PRODUCT_KEY_NAME_PDFM,
  PRODUCT_KEY_NAME_PDL,
  PRODUCT_KEY_NAME_PMM,
  PRODUCT_KEY_NAME_PTB,
  PRODUCT_KEY_NAME_PTB_CVT,
  PRODUCT_KEY_NAME_PTB_DNST,
  PRODUCT_KEY_NAME_PTB_DVT,
  PRODUCT_KEY_NAME_PTB_HDT,
  PRODUCT_KEY_NAME_PTBB,
  PRODUCT_KEY_NAME_PVAD,
  PRODUCT_KEY_NAME_RAS,
  PRODUCT_KEY_NAME_DAAS,
  PRODUCT_KEY_NAME_PBI,
  PRODUCT_KEY_NAME_RAS_AZMP
} from '@core/constants/subscriptions';

export const START_PAGE = 'index';
export const HOME_PAGE = 'home';
export const DASHBOARD_PAGE = 'dashboard';
export const ADD_LICENSES_FOR_PRODUCT_PAGE = 'addLicensesForProduct';
export const ADD_LICENSES_MODAL = 'add-licenses';
export const ADD_KEY_DASHBOARD_PAGE = 'addKeyDashboardPage';
export const ADD_KEY_MODAL = 'add-key';
export const ADD_LICENSES_DASHBOARD_PAGE = 'addLicensesDashboardPage';
export const RENEW_ONLINE_PAGE = 'renewOnline';
export const SSO_PRODUCT_LOGIN_PAGE = 'ssoProductLoginPage';
export const SSO_ERROR_PAGE = 'ssoErrorPage';

export const ASK_CONFIRM_ACCOUNT_PAGE = 'askConfirmAccount';
export const CONFIRM_ACCOUNT_PAGE = 'confirmAccount';
export const CONFIRM_BROWSER_PAGE = 'confirmBrowser';
export const TRUST_BROWSER_PAGE = 'trustBrowser';
export const CONFIRM_EMAIL_CHANGE_ACTION_PAGE = 'actionConfirmEmailChange';
export const CONFIRM_EMAIL_CHANGE_PHASE2_ACTION_PAGE = 'actionConfirmEmailChangePhase2';
export const CONFIRM_EMAIL_CHANGE_REVERT_ACTION_PAGE = 'actionEmailChangeRevert';
export const RESET_PASSWORD_ACTION_PAGE = 'actionPasswordReset';
export const ACCEPT_INVITE_ACTION_PAGE = 'acceptInvite';
export const CONFIRM_DELETE_ACCOUNT_ACTION_PAGE = 'confirmDeleteAccount';
export const DOWNLOAD_ACCOUNT_DATA_ACTION_PAGE = 'downloadAccountData';
export const UNSUBSCRIBE_FROM_NOTIFICATIONS_ACTION_PAGE = 'unsubscribeFromNotifications';
export const ADJUST_NOTIFICATIONS_ACTION_PAGE = 'adjustNotificationSettings';
export const RESTORE_PURCHASES_ACTION_PAGE = 'restorePurchases';
export const GET_RESCUE_CODES_ACTION_PAGE = 'getRescueCodes';
export const REPORT_ACCOUNT_ABUSE_ACTION_PAGE = 'reportAccountAbuse';
export const OPEN_PRODUCT_ACTION_PAGE = 'openProduct';
export const REDEEM_CODE_ABUSED_ACTION_PAGE = 'redeemCodeAbuse';

export const PVAD_SUBPAGE_SUBSCRIPTIONS = 'pvadSubscriptions';
export const PVAD_SUBPAGE_SUBSCRIPTION_DETAILS = 'pvadSubscriptionDetails';
export const PVAD_BETA_PAGE = 'pvadBeta';

export const PSW_SUBPAGE_SUBSCRIPTIONS = 'pswSubscriptions';
export const PSW_SUBPAGE_SUBSCRIPTION_DETAILS = 'pswSubscriptionDetails';
export const PSW_DOWNLOADS_PAGE = 'pswDownloads';

export const DAAS_SUBPAGE_SUBSCRIPTIONS = 'daasSubscriptions';
export const DAAS_SUBPAGE_SUBSCRIPTION_DETAILS = 'daasSubscriptionDetails';
export const DAAS_DOWNLOADS_PAGE = 'daasDownloads';

export const PBI_SUBPAGE_SUBSCRIPTIONS = 'pbiSubscriptions';
export const PBI_SUBPAGE_SUBSCRIPTION_DETAILS = 'pbiSubscriptionDetails';
export const PBI_DOWNLOADS_PAGE = 'pbiDownloads';

export const RAS_AZMP_SUBPAGE_SUBSCRIPTIONS = 'rasAzmpSubscriptions';
export const RAS_AZMP_SUBPAGE_SUBSCRIPTION_DETAILS = 'rasAzmpSubscriptionDetails';
export const RAS_AZMP_DOWNLOADS_PAGE = 'rasAzmpDownloads';
export const RAS_AZMP_MANAGED_APPS = 'rasAzmpManagedApps';

export const RAS_SUBPAGE_FARMS = 'farms';
export const RAS_SUBPAGE_DOWNLOADS = 'downloads';
export const RAS_SUBPAGE_SUBSCRIPTIONS = 'subscriptions';
export const RAS_SUBPAGE_SUBSCRIPTION_DETAILS = 'rasSubscriptionDetails';
export const RAS_SUBPAGE_OFFLINE_ACTIVATION = 'rasOfflineActivation';
export const RAS_SUBPAGE_OFFLINE_DEACTIVATION = 'rasOfflineDeactivation';

export const RAS_BETA_PAGE = 'rasBeta';
export const RAS_FARMS_PAGE = 'rasFarms';
export const RAS_DOWNLOADS_PAGE = 'rasDownloads';
export const RAS_SUBSCRIPTIONS_PAGE = 'rasSubscriptions';
export const RAS_SUBSCRIPTION_DETAILS_PAGE = 'rasSubscriptionDetails';
export const RAS_OFFLINE_ACTIVATION_PAGE = 'rasOfflineActivation';
export const RAS_OFFLINE_DEACTIVATION_PAGE = 'rasOfflineDeactivation';
export const SUBSCRIPTIONS_PAGE = 'subscriptions';
export const DOWNLOADS_PAGE = 'downloads';
export const HOSTS_PAGE = 'hosts';
export const SETTINGS_PAGE = 'settings';
export const OTHER_PAGE = 'other';
export const BUY_SUBSCRIPTION_MODAL = 'buySubscription';
export const RENEW_SUBSCRIPTION_MODAL = 'renewSubscription';

export const INVITATIONS_LIST_PAGE = 'invitationsList';
export const BUSINESS_PROFILE_SCOPE = 'businessProfile';
export const GENERAL_PAGE = 'general';
export const USERS_PAGE = 'users';
export const SSO_USERS_PAGE = 'users-sso';
export const IDP_INTEGRATION_PAGE = 'idp_integration';
export const PERSONAL_PROFILE_SCOPE = 'personalProfile';
export const SECURITY_PAGE = 'security';
export const NOTIFICATIONS_PAGE = 'notification';
export const EMAIL_CHANGE_SECURITY_NOTICE_PAGE = 'emailChangeSecurityNoticePage';
export const CANT_USE_RESCUE_CODES_PAGE = 'cantUseRescueCodesPage';
export const CHANGE_SETTINGS_MODE = 'changeSettings';
export const VERIFY_OTP_MODE = 'verifyOtp';
export const SSO_MEMBER_DETAILS_PAGE = 'businessProfileSSOMemberDetailsPage';

export const MFA_ONE_TIME_PASSWORD_PAGE = 'mfaOneTimePasswordPage';
export const MFA_RESCUE_CODE_PAGE = 'mfaRescueCodePage';
export const LOGIN_PAGE = 'login';
export const REGISTER_PAGE = 'register';
export const REGISTER_SOCIAL_PAGE = 'register-social';
export const FORGOT_PASSWORD_PAGE = 'forgot';
export const RESET_PASSWORD_PAGE = 'reset';
export const SOCIAL_LOGIN_ERRORS_PAGE = 'socialLoginErrors';

export const GET_SUPPORT_PAGE = 'getSupport';
export const USER_CONSENT_PAGE = 'userConsent';
export const GO_PAGE = 'go';
export const UNSUPPORTED_COUNTRY_PAGE = 'unsupportedCountry';
export const RATE_LIMIT_PAGE = 'rateLimit';
export const MAINTENANCE_INFO_PAGE = 'maintenanceInfo';
export const FORCE_AUTH_APP_MFA_PAGE = 'forceAuthAppMfaPage';

export const ADD_KEY_PAGE = 'addKeyPage';

export const DESKTOP_PREVIEW_PAGE = 'desktopPreview';
export const DESKTOP_M1_BETA_PAGE = 'desktopM1Beta';
export const REDEEM_CODES_PAGE = 'redeemCodes';
export const PACKAGED_LICENSES_PAGE = 'packagedLicenses';
export const DESKTOP_COMPUTERS_PAGE = 'desktopComputers';
export const DESKTOP_DOWNLOADS_PAGE = 'desktopDownloads';
export const DESKTOP_SUBSCRIPTIONS_PAGE = 'desktopSubscriptions';
export const DESKTOP_LEGACY_LICENSES_PAGE = 'desktopLegacyLicenses';
export const DESKTOP_SUBSCRIPTION_DETAILS_PAGE = 'desktopSubscriptionDetails';
export const DESKTOP_CONFIGURATION_PROFILES_PAGE = 'desktopConfigurationProfiles';
export const DESKTOP_SSO_USERS_PAGE = 'desktopSsoUsers';
export const DESKTOP_LITE_SUBSCRIPTIONS_PAGE = 'desktopLiteSubscriptions';
export const DESKTOP_LITE_SUBSCRIPTION_DETAILS_PAGE = 'desktopLiteSubscriptionDetails';
export const COMPUTERS_CONTENT = 'computers';
export const DOWNLOADS_CONTENT = 'downloads';
export const SUBSCRIPTIONS_CONTENT = 'subscriptions';
export const DESKTOP_SUBSCRIPTION_DETAILS_CONTENT = 'desktopSubscriptionDetails';
export const CONFIGURATION_PROFILES_CONTENT = 'configurationProfiles';
export const SUBSCRIPTION_DETAILS_CONTENT = 'subscriptionDetails';
export const SSO_USERS_CONTENT = 'ssoUsersContent';
export const DESKTOP_SSO_MEMBER_DETAILS_PAGE = 'desktopSSOMemberDetailsPage';

export const PMM_SUBSCRIPTION_DETAILS = 'pmmSubscriptionDetails';
export const PMM_DOWNLOADS_PAGE = 'pmmDownloads';
export const TOOLBOX_SUBSCRIPTION = 'toolboxSubscriptions';
export const TOOLBOX_SUBSCRIPTION_DETAILS = 'toolboxSubscriptionDetails';
export const TOOLBOX_DOWNLOADS_PAGE = 'toolboxDownloads';
export const TOOLBOX_UTILITY_SUBSCRIPTION = 'toolboxUtilitySubscriptions';
export const TOOLBOX_UTILITY_SUBSCRIPTION_DETAILS = 'toolboxUtilitySubscriptionDetails';
export const TOOLBOX_UTILITY_DOWNLOADS_PAGE = 'toolboxUtilityDownloads';

export const SUBSCRIPTION_DETAILS_PAGE = 'subscriptionDetails';
export const CONVERT_PDL_PAGE = 'convertPdl';

export const LEGACY_LICENSES_PAGE = 'legacyLicenses';
export const LEGACY_LICENSES_CONTENT = 'legacy';

export const PRODUCT_CONTEXT_TO_ROUTE_MAP = {
  [PRODUCT_KEY_NAME_PDB]: DESKTOP_SUBSCRIPTION_DETAILS_PAGE,
  [PRODUCT_KEY_NAME_PDFC]: DESKTOP_SUBSCRIPTION_DETAILS_PAGE,
  [PRODUCT_KEY_NAME_PDFM]: DESKTOP_SUBSCRIPTION_DETAILS_PAGE,
  [PRODUCT_KEY_NAME_PDL]: DESKTOP_LITE_SUBSCRIPTION_DETAILS_PAGE,
  [PRODUCT_KEY_NAME_RAS]: RAS_SUBPAGE_SUBSCRIPTION_DETAILS,
  [PRODUCT_KEY_NAME_PMM]: PMM_SUBSCRIPTION_DETAILS,
  [PRODUCT_KEY_NAME_PTB]: TOOLBOX_SUBSCRIPTION_DETAILS,
  [PRODUCT_KEY_NAME_PTBB]: TOOLBOX_SUBSCRIPTION_DETAILS,
  [PRODUCT_KEY_NAME_PVAD]: PVAD_SUBPAGE_SUBSCRIPTION_DETAILS,
  [PRODUCT_KEY_NAME_PTB_HDT]: TOOLBOX_UTILITY_SUBSCRIPTION_DETAILS,
  [PRODUCT_KEY_NAME_PTB_DNST]: TOOLBOX_UTILITY_SUBSCRIPTION_DETAILS,
  [PRODUCT_KEY_NAME_PTB_DVT]: TOOLBOX_UTILITY_SUBSCRIPTION_DETAILS,
  [PRODUCT_KEY_NAME_PTB_CVT]: TOOLBOX_UTILITY_SUBSCRIPTION_DETAILS,
  [PRODUCT_KEY_NAME_PSW]: PSW_SUBPAGE_SUBSCRIPTION_DETAILS,
  [PRODUCT_KEY_NAME_DAAS]: DAAS_SUBPAGE_SUBSCRIPTION_DETAILS,
  [PRODUCT_KEY_NAME_PBI]: PBI_SUBPAGE_SUBSCRIPTION_DETAILS,
  [PRODUCT_KEY_NAME_RAS_AZMP]: RAS_AZMP_SUBPAGE_SUBSCRIPTION_DETAILS,
};

export const PRODUCT_CONTEXT_TO_DOWNLOAD_MAP = {
  [PRODUCT_KEY_NAME_PSW]: { name: PSW_DOWNLOADS_PAGE },
  [PRODUCT_KEY_NAME_PDB]: { name: DESKTOP_DOWNLOADS_PAGE, params: { product: PRODUCT_KEY_NAME_PDB } },
  [PRODUCT_KEY_NAME_PDB_PER_USER]: { name: DESKTOP_DOWNLOADS_PAGE, params: { product: PRODUCT_KEY_NAME_PDB_PER_USER } },
  [PRODUCT_KEY_NAME_PDFC]: { name: DESKTOP_DOWNLOADS_PAGE, params: { product: PRODUCT_KEY_NAME_PDFC } },
  [PRODUCT_KEY_NAME_PDFM]: { name: DESKTOP_DOWNLOADS_PAGE, params: { product: PRODUCT_KEY_NAME_PDFM } },
  [PRODUCT_KEY_NAME_PMM]: { name: PMM_DOWNLOADS_PAGE },
  [PRODUCT_KEY_NAME_RAS]: { name: RAS_DOWNLOADS_PAGE },
  [PRODUCT_KEY_NAME_DAAS]: { name: DAAS_DOWNLOADS_PAGE },
  [PRODUCT_KEY_NAME_PBI]: { name: PBI_DOWNLOADS_PAGE },
  [PRODUCT_KEY_NAME_RAS_AZMP]: { name: RAS_AZMP_DOWNLOADS_PAGE },
  [PRODUCT_KEY_NAME_PTB]: (utilityKeyName?: string) => ({ name: utilityKeyName ? TOOLBOX_UTILITY_DOWNLOADS_PAGE : TOOLBOX_DOWNLOADS_PAGE, params: { utility: utilityKeyName } }),
  toolbox: { name: TOOLBOX_DOWNLOADS_PAGE },
};
