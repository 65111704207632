/**
 * File: sorters.ts
 *
 * Copyright:
 * Copyright 2017-2021. Parallels International GmbH. All Rights Reserved.
 *
 * */
type CmpResult = -1 | 0 | 1;
type WithWildcards<T> = T & { [key: string]: any };

export const sortByValue = function<T> (a: T, b: T, asc: boolean): CmpResult {
  if (a === b) {
    return 0;
  } else if (asc) {
    return a < b ? -1 : 1;
  } else if (!asc) {
    return a > b ? -1 : 1;
  }
};

export const sortByValueWithPermanent = function<T> (a: T, b: T, asc: boolean, permanent: T): CmpResult {
  // permanent is always on top
  if (a === permanent) {
    return -1;
  } else if (b === permanent) {
    return 1;
  } else if (a === b) {
    return 0;
  } else if (asc) {
    return a < b ? -1 : 1;
  } else if (!asc) {
    return a > b ? -1 : 1;
  }
};

export const sortByDate = sortByValue;

export const sortByIp = function (ip1: string, ip2: string, asc: boolean): CmpResult {
  let i = 0;
  let sort;
  const a = ip1.split('.');
  const b = ip2.split('.');

  for (i = 0; i < a.length; i++) {
    sort = sortByValue(parseInt(a[i]), parseInt(b[i]), asc);
    if (sort) {
      return sort;
    }
  }
  return 0;
};

export const sortListDictsByText = function (
  a: WithWildcards<{ text: string}>,
  b: WithWildcards<{ text: string }>
): CmpResult {
  if (a.text < b.text) return -1;
  if (a.text > b.text) return 1;
  return 0;
};
