/**
 * File: mfa.ts
 *
 * Copyright:
 * Copyright 2021-2021. Parallels International GmbH. All Rights Reserved.
 *
 */

export enum MFA_METHOD {
    EMAIL_ALWAYS = 1,
    EMAIL_SENSITIVE_DATA = 2,
    AUTH_APPLICATION = 3
}
